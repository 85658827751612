import { all, fork } from 'redux-saga/effects'

import { loginWatcherSaga } from 'features/account/store/login.sagas'
import { organizationWatcherSaga } from 'features/organization/store/organization.sagas'

export function* rootSaga() {
  yield all([fork(loginWatcherSaga), fork(organizationWatcherSaga)])
}

export default rootSaga
