export const DarkColors = {
  colorMode: 'dark' as 'light' | 'dark',
  primary: {
    main: '#3D5567', // Default - Tab Selected
    light: '#212121', // Default - Button
  },
  secondary: {
    main: '#424242', // used- borderColor
    light: '#3D5567', // used - Button
  },
  background: {
    default: '#000000', // used
    paper: '#191919', // used
  },
  text: {
    primary: '#FFFFFF', // used
    secondary: '#3D5567', // used
  },
  gccCustomColors: {
    boxTitle: '#1E2A33',
    iconButtonMain: '#5B7F9A',
    textDescription: '#ffffff',
    subMessage: '#ffffff',
    notifIconBorder: '#d0d4cd',
    lightGreen: '#00B4804D',
    boxContent: 'rgba(61, 85, 103, 1)',
    iconColorSub: 'rgba(255, 255, 255, 1)',
    tableRowMain: '#191919',
    tableRowSub: '#000000',
    tableRowHover: '#192929',
  },
}
