import { useCallback } from 'react'

import { TableDataCall } from 'components/Table/api'
import { TableResponse } from 'components/Table/types'
import {
  forgotPasswordCall,
  changePasswordCall,
  resetPasswordCall,
  loginCall,
  fetchProfileDetails,
  updateProfile,
  updateProfileImage,
  fetchUserSettings,
  updateUserSettings,
  fetchNotificationSettings,
  saveNotificationSettings,
} from 'features/account/api'
import {
  getProfileDetails,
  loginActions,
  loginDetails,
  getSourceHospitalScreen,
  getSourceHospitalList,
} from 'features/account/store'
import {
  LoginFormInput,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginUserInfo,
  ChangePasswordRequest,
  ResetPasswordRequest,
  LoginResponse,
  Profile,
  UserSettingInfo,
  CreateRationaleReq,
  UpdateRationaleReq,
  GetRationaleReq,
  CreateDomoDbReq,
  UpdateDomoDbReq,
  UpdateNotifReq,
  UpdateDomoDbPriorityReq,
  GetSourceHospitalReq,
  CreateSourceHospitalReq,
  UpdateSourceHospital,
  ReqBody,
  MapHospitalBody,
  SourceHospital,
} from 'features/account/types'
import {
  addHoldRationale,
  updateHoldRationale,
  deleteHoldRationale,
  addLostRationale,
  updateLostRationale,
  deleteLostRationale,
  addDOMODbLabel,
  updateDOMODbLabel,
  deleteDOMODbLabel,
  getDOMODashboardList,
  updateDOMODbPriority,
  addSourceHospital,
  updateSourceHospital,
  deleteSourceHospital,
  getMappedSourceHospitals,
  getUnmappedSourceHospitals,
  mapSourceHospital,
  getAllSourceHospitals,
} from 'features/referrals/api'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Envelope } from 'utils/types/Envelope'

import { getPermissionIds, getMenuIds, getSelectedRoleLevel, getSelectedFacilities } from '../store'

export type LoginServiceOperators = {
  loginInfo: LoginUserInfo
  login: (data: LoginFormInput) => Promise<LoginResponse>
  forgotPassword: (data: ForgotPasswordRequest) => Promise<ForgotPasswordResponse>
  changePassword: (changePassword: ChangePasswordRequest) => Promise<ForgotPasswordResponse>
  resetPassword: (resetPassword: ResetPasswordRequest) => Promise<ForgotPasswordResponse>
  clearLoginDetails: () => void
  setPermissionIds: (data: number[]) => void
  setMenuIds: (data: number[]) => void
  setSelectedRoleLevel: (data: number) => void
  setSelectedFacilities: (data: number[]) => void
  setProfileData: (data: Profile) => void
  getPermissionIds: number[]
  getMenuIds: number[]
  getSelectedRoleLevel: number
  getSelectedFacilities: number[]
  getProfileData: Profile
  sourceHospitalScreen: number
  sourceHospitalList: SourceHospital[]

  getProfileDetail: (id: string) => Promise<Envelope>
  updateProfileDetails: (data: Profile) => Promise<Envelope>
  updateProfilePic: (data: FormData) => Promise<Envelope>

  fetchUserSettings: (id: string) => Promise<Envelope>
  updateUserSettings: (data: UserSettingInfo) => Promise<Envelope>
  fetchHoldList: (data: GetRationaleReq, endPoint: string) => Promise<TableResponse>
  updateHoldReason: (data: UpdateRationaleReq) => Promise<Envelope>
  createHoldReason: (data: CreateRationaleReq) => Promise<Envelope>
  deleteHoldReason: (id: string) => Promise<Envelope>
  fetchLostList: (data: GetRationaleReq, endPoint: string) => Promise<TableResponse>
  updateLostReason: (data: UpdateRationaleReq) => Promise<Envelope>
  createLostReason: (data: CreateRationaleReq) => Promise<Envelope>
  deleteLostReason: (id: string) => Promise<Envelope>

  getDashboardList: () => Promise<Envelope>
  updateDashboardLabel: (data: UpdateDomoDbReq) => Promise<Envelope>
  createDashboardLabel: (data: CreateDomoDbReq) => Promise<Envelope>
  deleteDashboardLabel: (id: string) => Promise<Envelope>

  listAllNotificationSettings: () => Promise<Envelope>
  updateNotificationList: (data: UpdateNotifReq) => Promise<Envelope>
  setDiscussionNotification: (data: string) => void
  updateDOMODashboardPriority: (data: UpdateDomoDbPriorityReq) => Promise<Envelope>
  fetchSourceHospital: (data: GetSourceHospitalReq, endPoint: string) => Promise<TableResponse>
  createSourceHospital: (data: CreateSourceHospitalReq) => Promise<Envelope>
  updateSourceHospital: (data: UpdateSourceHospital) => Promise<Envelope>
  deleteSourceHospital: (id: string) => Promise<Envelope>
  setSourceHospitalScreen: (val: number) => void
  setSourceHospitalList: (val: SourceHospital[]) => void
  getMappedSourceHospitals: (data: ReqBody) => Promise<Envelope>
  getUnmappedSourceHospitals: (data: string) => Promise<Envelope>
  mapSourceHospital: (data: MapHospitalBody) => Promise<Envelope>

  getAllSourceHospitals: () => Promise<Envelope>
}

/**
 * PostService custom-hooks
 * @see https://reactjs.org/docs/hooks-custom.html
 */
export const useLoginService = (): Readonly<LoginServiceOperators> => {
  const dispatch = useAppDispatch()
  return {
    loginInfo: useAppSelector(loginDetails),
    login: useCallback((loginData: LoginFormInput) => loginCall(loginData), []),
    forgotPassword: useCallback(
      (forgotPassword: ForgotPasswordRequest) => forgotPasswordCall(forgotPassword),
      [],
    ),
    changePassword: useCallback(
      (changePassword: ChangePasswordRequest) => changePasswordCall(changePassword),
      [],
    ),
    resetPassword: useCallback(
      (resetPassword: ResetPasswordRequest) => resetPasswordCall(resetPassword),
      [],
    ),
    clearLoginDetails: useCallback(() => {
      dispatch(loginActions.clearLoginDetails())
    }, [dispatch]),
    setPermissionIds: useCallback(
      data => {
        dispatch(loginActions.setPermissionIds(data))
      },
      [dispatch],
    ),
    setMenuIds: useCallback(
      data => {
        dispatch(loginActions.setMenuIds(data))
      },
      [dispatch],
    ),
    setSelectedRoleLevel: useCallback(
      data => {
        dispatch(loginActions.setSelectedRoleLevel(data))
      },
      [dispatch],
    ),
    setSelectedFacilities: useCallback(
      data => {
        dispatch(loginActions.setSelectedFacilities(data))
      },
      [dispatch],
    ),
    setProfileData: useCallback(
      data => {
        dispatch(loginActions.setProfileDetails(data))
      },
      [dispatch],
    ),
    getPermissionIds: useAppSelector(getPermissionIds),
    getMenuIds: useAppSelector(getMenuIds),
    getSelectedRoleLevel: useAppSelector(getSelectedRoleLevel),
    getSelectedFacilities: useAppSelector(getSelectedFacilities),
    getProfileData: useAppSelector(getProfileDetails),

    getProfileDetail: useCallback(id => fetchProfileDetails(id), []),
    updateProfileDetails: useCallback(data => updateProfile(data), []),
    updateProfilePic: useCallback(data => updateProfileImage(data), []),

    fetchUserSettings: useCallback(id => fetchUserSettings(id), []),
    updateUserSettings: useCallback(data => updateUserSettings(data), []),
    fetchHoldList: useCallback(
      (data: GetRationaleReq, endPoint: string) => TableDataCall(data, endPoint),
      [],
    ),
    createHoldReason: useCallback((data: CreateRationaleReq) => addHoldRationale(data), []),
    updateHoldReason: useCallback(
      (editholdid: UpdateRationaleReq) => updateHoldRationale(editholdid),
      [],
    ),
    deleteHoldReason: useCallback((id: string) => deleteHoldRationale(id), []),
    fetchLostList: useCallback(
      (data: GetRationaleReq, endPoint: string) => TableDataCall(data, endPoint),
      [],
    ),
    createLostReason: useCallback((data: CreateRationaleReq) => addLostRationale(data), []),
    updateLostReason: useCallback(
      (editholdid: UpdateRationaleReq) => updateLostRationale(editholdid),
      [],
    ),
    deleteLostReason: useCallback((id: string) => deleteLostRationale(id), []),
    getDashboardList: useCallback(() => getDOMODashboardList(), []),

    createDashboardLabel: useCallback((data: CreateDomoDbReq) => addDOMODbLabel(data), []),
    deleteDashboardLabel: useCallback((id: string) => deleteDOMODbLabel(id), []),
    updateDashboardLabel: useCallback(
      (editLabel: UpdateDomoDbReq) => updateDOMODbLabel(editLabel),
      [],
    ),
    listAllNotificationSettings: useCallback(() => fetchNotificationSettings(), []),
    updateNotificationList: useCallback(
      (updateList: UpdateNotifReq) => saveNotificationSettings(updateList),
      [],
    ),
    setDiscussionNotification: useCallback(data => {
      dispatch(loginActions.setDiscussionNotif(data))
    }, []),

    updateDOMODashboardPriority: useCallback(
      (data: UpdateDomoDbPriorityReq) => updateDOMODbPriority(data),
      [],
    ),
    fetchSourceHospital: useCallback(
      (data: GetSourceHospitalReq, endPoint: string) => TableDataCall(data, endPoint),
      [],
    ),
    createSourceHospital: useCallback(
      (data: CreateSourceHospitalReq) => addSourceHospital(data),
      [],
    ),
    updateSourceHospital: useCallback(
      (editSourceId: UpdateSourceHospital) => updateSourceHospital(editSourceId),
      [],
    ),
    deleteSourceHospital: useCallback((id: string) => deleteSourceHospital(id), []),
    sourceHospitalScreen: useAppSelector(getSourceHospitalScreen),
    setSourceHospitalScreen: useCallback((val: number) => {
      dispatch(loginActions.setSourceHospitalScreen(val))
    }, []),
    getMappedSourceHospitals: useCallback((body: ReqBody) => getMappedSourceHospitals(body), []),
    getUnmappedSourceHospitals: useCallback((id: string) => getUnmappedSourceHospitals(id), []),
    mapSourceHospital: useCallback((body: MapHospitalBody) => mapSourceHospital(body), []),
    getAllSourceHospitals: useCallback(() => getAllSourceHospitals(), []),
    sourceHospitalList: useAppSelector(getSourceHospitalList),
    setSourceHospitalList: useCallback((val: SourceHospital[]) => {
      dispatch(loginActions.setSourceHospitalList(val))
    }, []),
  }
}

export default useLoginService
