import { useCallback } from 'react'

import { TableResponse } from 'components/Table/types'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { Envelope } from 'utils/types/Envelope'

import { getNotificationsList, setNotificationsReadStatus } from '../api'
import {
  getAllNotificationList,
  getUnreadNotificationList,
  getNewNotificationReceivedStatus,
  NotificationsActions,
} from '../store'
import { NotificationResponse, NotificationsParam, UpdateNotificationParam } from '../types'

export type NotificationsServiceOperators = {
  getNotifications: (data: NotificationsParam) => Promise<TableResponse>
  setNotificationsRead: (data: UpdateNotificationParam) => Promise<Envelope>
  setUnreadNotificationList: (data: NotificationResponse) => void
  setAllNotificationList: (data: NotificationResponse) => void
  setnewNotificationReceivedStatus: () => void
  getUnreadNotificationList: NotificationResponse
  getAllNotificationList: NotificationResponse
  getNewNotificationReceivedStatus: boolean
}

export const useNotificationsService = (): Readonly<NotificationsServiceOperators> => {
  const dispatch = useAppDispatch()

  return {
    getNotifications: useCallback((data: NotificationsParam) => getNotificationsList(data), []),
    setNotificationsRead: useCallback(
      (data: UpdateNotificationParam) => setNotificationsReadStatus(data),
      [],
    ),
    setUnreadNotificationList: useCallback(notificationData => {
      dispatch(NotificationsActions.setUnreadNotificationList(notificationData))
    }, []),
    setAllNotificationList: useCallback(allNotificationData => {
      dispatch(NotificationsActions.setAllNotificationList(allNotificationData))
    }, []),
    setnewNotificationReceivedStatus: useCallback(() => {
      dispatch(NotificationsActions.setnewNotificationReceivedStatus())
    }, []),
    getUnreadNotificationList: useAppSelector(getUnreadNotificationList),
    getAllNotificationList: useAppSelector(getAllNotificationList),
    getNewNotificationReceivedStatus: useAppSelector(getNewNotificationReceivedStatus),
  }
}

export default useNotificationsService
