import { createTheme } from '@mui/material/styles'

import { DarkColors } from '../constants/DarkColors'

type ColorProps = {
  colorMode: 'light' | 'dark'
  primary: object
  secondary: object
  background: object
  text: object
  gccCustomColors: object
}

declare module '@mui/material/styles' {
  interface PaletteOptions {
    gccCustomColors?: object
  }
}

export const theme = (data: ColorProps) => {
  const { primary, secondary, background, text, colorMode, gccCustomColors } = data
  return createTheme({
    palette: {
      mode: colorMode,
      action: {
        disabledBackground: DarkColors.primary.main,
        disabled: DarkColors.text.primary,
      },
      primary: primary || {
        light: DarkColors.primary.light,
        main: DarkColors.primary.main,
      },
      secondary: secondary || {
        light: DarkColors.secondary.light,
        main: DarkColors.secondary.main,
      },
      background: background || {
        default: DarkColors.background.default,
        paper: DarkColors.background.paper,
      },

      text: text || {
        primary: DarkColors.text.primary,
        secondary: DarkColors.text.secondary,
      },
      gccCustomColors: gccCustomColors || {
        boxTitle: DarkColors.gccCustomColors.boxTitle,
        iconButtonMain: DarkColors.gccCustomColors.iconButtonMain,
        textDescription: DarkColors.gccCustomColors.textDescription,
        subMessage: DarkColors.gccCustomColors.subMessage,
        lightGreen: DarkColors.gccCustomColors.lightGreen,
        notifIconBorder: DarkColors.gccCustomColors.notifIconBorder,
        boxContent: DarkColors.gccCustomColors.boxContent,
        iconColorSub: DarkColors.gccCustomColors.iconColorSub,
        tableRowMain: DarkColors.gccCustomColors.tableRowMain,
        tableRowSub: DarkColors.gccCustomColors.tableRowSub,
        tableRowHover: DarkColors.gccCustomColors.tableRowHover,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1200,
        xl: 1500,
      },
    },
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      h1: {
        fontSize: 'clamp(2rem, 3.5vw, 96px)',
        fontWeight: 300,
      },
      h2: {
        fontSize: 'clamp(1.5rem, 3vw, 60px)',
        fontWeight: 300,
      },
      h3: {
        fontSize: 'clamp(1.17rem, 2.5vw, 48px)',
        fontWeight: 400,
      },
      h4: {
        fontSize: 'clamp(1rem, 2vw, 34px)',
        fontWeight: 600,
      },
      h5: {
        fontSize: 'clamp(18px, 1.8vw, 24px)',
        fontWeight: 600,
      },
      h6: {
        fontSize: 'clamp(16px, 1.7vw, 20px)',
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 'clamp(0.83rem, 2vw, 16px)',
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 'clamp(12px, 1.5vw, 14px)',
        fontWeight: 500,
      },
      body1: {
        fontSize: 'clamp(12px, 1.4vw, 16px)',
        fontWeight: 400,
      },
      body2: {
        fontSize: 'clamp(10px, 1.3vw, 14px)',
        fontWeight: 400,
      },
      caption: {
        fontSize: 'clamp(0.67rem, 1.5vw, 12px)',
        fontWeight: 400,
      },
      button: {
        fontSize: 'clamp(12px, 1.5vw, 15px)',
        fontWeight: 500,
      },
      overline: {
        fontSize: 'clamp(10px, 1vw, 12px)',
        fontWeight: 400,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'secondary.main !important' },
            },
            '& .Mui-disabled': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colorMode === 'light' ? '#E0E0E0 !important' : '#424242 !important',
              },
            },
          },
        },
      },
    },
  })
}
