// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { FacilityList } from 'features/facilities/types'
import type { RootState } from 'store/store'

export interface FacilityState {
  facilityList: FacilityList
  selectedFacility: { id: number; name: string; facilityUser: boolean }
  activeTab: number

  integrationTabToggler: {
    careport: boolean
    fax: boolean
    pcc: boolean
    careportId: string
    pccId: string
  }
}
const initialState: FacilityState = {
  facilityList: { data: [], totalCount: 0, hasData: true },
  selectedFacility: { id: 0, name: '', facilityUser: false },
  activeTab: 0,

  integrationTabToggler: { careport: false, pcc: false, fax: false, careportId: '', pccId: '' },
}
// slice
export const facilitySlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    setFacilityList: (state, action) => {
      state.facilityList = action.payload
    },
    setSelectedFacility: (state, action) => {
      state.selectedFacility = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setIntegrationTabToggler: (state, action) => {
      state.integrationTabToggler = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const facilityActions = {
  setFacilityList: facilitySlice.actions.setFacilityList,
  setSelectedFacility: facilitySlice.actions.setSelectedFacility,
  setActiveTab: facilitySlice.actions.setActiveTab,
  setIntegrationTabToggler: facilitySlice.actions.setIntegrationTabToggler,
}

// Selectors
export const selectFacilityList = (state: RootState) => state.facility.facilityList
export const selectedFacility = (state: RootState) => state.facility.selectedFacility
export const getActiveTab = (state: RootState) => state.facility.activeTab

export const getIntegrationTabToggler = (state: RootState) => state.facility.integrationTabToggler
// Reducer
export default facilitySlice.reducer
