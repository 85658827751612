import { TableResponse } from 'components/Table/types'
import { Env } from 'config/Env'
import { Envelope } from 'utils/types/Envelope'

import { HttpClient, ApiRequestTypes } from '../../../utils/apiService/httpService'
import { NotificationsParam, UpdateNotificationParam } from '../types'

const GET_NOTIFICATIONSLIST = `${Env.REACT_APP_API_URL}Referral/api/Notification/get`
const SET_NOTIFICATION_READ_STATUS = `${Env.REACT_APP_API_URL}Referral/api/Notification/UpdateNotifications`

export const getNotificationsList = (body: NotificationsParam): Promise<TableResponse> =>
  HttpClient[ApiRequestTypes.post](GET_NOTIFICATIONSLIST, body)
export const setNotificationsReadStatus = (body: UpdateNotificationParam): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](SET_NOTIFICATION_READ_STATUS, body)
