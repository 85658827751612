// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from 'store/store'

import { RolesListType } from '../types'

export interface RolesPermissionState {
  rolesList: { data: []; totalCount: number; hasData: boolean }
  selectedRole: RolesListType
}

const initialState: RolesPermissionState = {
  rolesList: { data: [], totalCount: 0, hasData: false },
  selectedRole: { roleId: '', roleName: '', isCustom: false, levelId: 0 },
}

// slice
export const rolesPermissionSlice = createSlice({
  name: 'rolesPermission',
  initialState,
  reducers: {
    setRoleList: (state, action) => {
      state.rolesList = action.payload
    },
    setSeletedRole: (state, action) => {
      state.selectedRole = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const rolesPermissionActions = {
  setRoleList: rolesPermissionSlice.actions.setRoleList,
  setSelectedRole: rolesPermissionSlice.actions.setSeletedRole,
}

// Selectors
export const listOfRoles = (state: RootState) => state.roles.rolesList
export const selectedRole = (state: RootState) => state.roles.selectedRole

// Reducer
export default rolesPermissionSlice.reducer
