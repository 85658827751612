import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { GroupChannelHandler, Member } from '@sendbird/chat/groupChannel'
import { OpenChannelHandler } from '@sendbird/chat/openChannel'
import Cookies from 'js-cookie'
import jwt from 'jwt-decode'
import { SnackbarProvider } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { Provider } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'

import 'App.css'
import { SnackbarUtilsConfigurator } from 'components/Snackbar'
import { useThemeService } from 'components/Theme/hooks/useThemeService'
import Env from 'config/Env'
import { DarkColors } from 'constants/DarkColors'
import { LightColors } from 'constants/LightColors'
import { useLoginService } from 'features/account/hooks'
import { LoginUserInfo } from 'features/account/types'
import { useDiscussionService } from 'features/discussions/hooks'
import { useNotificationsService } from 'features/notifications/hooks'
import { NotificationResponse } from 'features/notifications/types'
import { useReferralsService } from 'features/referrals/hooks'
import { TaskListRequest, TodoListResponse } from 'features/referrals/types'
import AppRoutes from 'routes'
import { store } from 'store/store'
import { setInterceptors } from 'utils/apiService/httpService'
import { permissions } from 'utils/genericData/getPermissions'
import {
  asyncConnectUser,
  asyncGetUnreadChatList,
  asyncJoinOpenChannel,
  getUnreadChatCountSb,
  groupChannelListenerName,
  openChannelListenerName,
  sbChatClient,
} from 'utils/getChatClient'
import { showApiFailedSnackbar, showExceptionSnackbar } from 'utils/getSnackbarMessage'

import { theme as defaultTheme } from './config/Theme'
import { history } from './utils/history/history'

const App = () => {
  const { getTheme } = useThemeService()
  const [permissionArray, setPermissionArray] = useState<number[]>([])
  const userId = Cookies.get('chat_user_id')
  const userSessionToken = Cookies.get('gcc_chat_token')
  const { getNotifications, setUnreadNotificationList, setnewNotificationReceivedStatus } =
    useNotificationsService()
  const { getSelectedRoleLevel, getSelectedFacilities, loginInfo, getPermissionIds } =
    useLoginService()
  const { getTodoList, setIsTaskListPending } = useReferralsService()
  const {
    setNewChatDetails,
    setDeletedChatDetails,
    setChatNotificationList,
    setUnreadChatThreadCount,
    setNewMemberDetails,
  } = useDiscussionService()
  const theme = useMemo(
    () => defaultTheme(getTheme === 'light' ? LightColors : DarkColors),
    [getTheme],
  )
  useEffect(() => {
    const token = Cookies.get('gcc_access_token')
    if (token) setInterceptors('application/json', token)
  }, [])

  const defaultFacList = () => {
    if (getSelectedRoleLevel === 2) {
      return getSelectedFacilities?.map(facItem => facItem?.toString())
    }
    return []
  }

  const isDiscussionNotificationActive = () => loginInfo?.showDiscussion?.toLowerCase() === 'true'

  const updateDiscussionNotification = () => {
    if (isDiscussionNotificationActive()) {
      getUnreadChatCountSb(setUnreadChatThreadCount, defaultFacList())
      asyncGetUnreadChatList(setChatNotificationList, defaultFacList())
    }
  }

  const groupChannelHandler = new GroupChannelHandler({
    onMessageReceived: (channel, message) => {
      const chatBody = {
        channel,
        message,
      }
      setNewChatDetails(chatBody)
      updateDiscussionNotification()
    },
    onMessageDeleted: (channel, messageId) => {
      const chatBody = {
        channel,
        messageId,
      }
      setDeletedChatDetails(chatBody)
      updateDiscussionNotification()
    },
    onUserJoined: (channel, user) => {
      const newUser = user as unknown as Member
      const memberBody = {
        channel,
        user: newUser,
      }
      setNewMemberDetails(memberBody)
    },
  })

  const getNotificationFunc = (logUser: LoginUserInfo) => {
    const body = {
      pageNumber: 1,
      pageSize: 10,
      sort: '',
      search: '',
      id: logUser?.userId,
      isRead: false,
      facilityIds: defaultFacList(),
    }
    getNotifications(body).then(res => {
      setUnreadNotificationList(res?.data as NotificationResponse)
      setnewNotificationReceivedStatus()
    })
  }

  const checkPendingTaskList = (requestBody: TaskListRequest) => {
    getTodoList(requestBody)
      .then(res => {
        if (res.data.isSuccess) {
          const taskList = res?.data as unknown as TodoListResponse
          if (taskList?.totalCount && taskList?.totalCount !== undefined) {
            setIsTaskListPending(true)
          }
        } else {
          showApiFailedSnackbar(res?.data?.message)
        }
      })
      .catch(error => {
        showExceptionSnackbar(error)
      })
  }

  const checkPermissions = () => {
    const acceptOrDenyPermissions = []

    if (
      getPermissionIds.includes(
        permissions?.filter(item => item.code === 'taskList-accept')?.[0]?.id,
      )
    ) {
      acceptOrDenyPermissions.push(
        permissions?.filter(item => item.code === 'taskList-accept')?.[0]?.id,
      )
    }

    if (
      getPermissionIds.includes(permissions?.filter(item => item.code === 'taskList-deny')?.[0]?.id)
    ) {
      acceptOrDenyPermissions.push(
        permissions?.filter(item => item.code === 'taskList-deny')?.[0]?.id,
      )
    }

    return acceptOrDenyPermissions
  }

  const defaultOrgList = () => {
    if (getSelectedRoleLevel === 1 || getSelectedRoleLevel === 2)
      return [loginInfo.organizationId as number]
    return []
  }

  useEffect(() => {
    const permissionArrayValues = checkPermissions()
    setPermissionArray(permissionArrayValues)
  }, [getPermissionIds])

  const getToDoListItems = () => {
    const body: TaskListRequest = {
      pageNumber: 1,
      pageSize: 10,
      sort: '',
      search: '',
      permissionList: permissionArray,
      taskListStatus: 1,
      filterParams: [
        {
          name: 'facilityIds',
          value: defaultFacList().map(item => item?.toString()),
        },
        {
          name: 'organizationId',
          value: defaultOrgList().map(item => item?.toString()),
        },
        { name: 'taskType', value: [] },
      ],
    }
    if (permissionArray?.length && permissionArray?.length !== undefined) {
      checkPendingTaskList(body)
    }
  }

  const openChannelHandler = new OpenChannelHandler({
    onMessageReceived: (channel, message) => {
      const user = Cookies.get('chat_user_id')
      const token = Cookies.get('gcc_access_token') ?? ''
      const loginUserInfo = jwt(token)

      if (user && message?.mentionedUserIds?.includes(user)) {
        if (channel?.url === Env.TASKLIST_CHANNEL_ID) {
          getToDoListItems()
          return
        }
        getNotificationFunc(loginUserInfo as LoginUserInfo)
      }
    },
  })

  useEffect(() => {
    if (userId && userSessionToken && userId !== undefined && userSessionToken !== undefined) {
      asyncConnectUser(userId, userSessionToken).then(() => {
        asyncJoinOpenChannel()
        sbChatClient.groupChannel.addGroupChannelHandler(
          groupChannelListenerName,
          groupChannelHandler,
        )
        sbChatClient.openChannel.addOpenChannelHandler(openChannelListenerName, openChannelHandler)
        if (loginInfo?.userId) {
          getUnreadChatCountSb(setUnreadChatThreadCount, defaultFacList())
          asyncGetUnreadChatList(setChatNotificationList, defaultFacList())
        }
      })
    }
    return () => {
      // Release any resources or perform cleanup here
      sbChatClient.groupChannel.removeGroupChannelHandler(groupChannelListenerName)
      sbChatClient.openChannel.removeOpenChannelHandler(openChannelListenerName)
    }
  }, [userId, userSessionToken, getSelectedFacilities])

  useEffect(() => {
    if (
      permissionArray?.length &&
      permissionArray?.length !== undefined &&
      userSessionToken &&
      userSessionToken !== undefined
    ) {
      getToDoListItems()
    }
  }, [permissionArray, loginInfo.organizationId, userSessionToken])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
          maxSnack={4}
          hideIconVariant={true}
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          <SnackbarUtilsConfigurator />
          <AppRoutes />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const WrapperApp = () => (
  <Provider store={store}>
    <HistoryRouter history={history}>
      <App />
    </HistoryRouter>
  </Provider>
)

export default WrapperApp
