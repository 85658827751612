/* eslint-disable @typescript-eslint/no-explicit-any */
// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from 'store/store'

import { NotificationResponse } from '../types'

export interface NotificationsState {
  unReadNotificationList: NotificationResponse
  allNotificationList: NotificationResponse
  newNotificationReceivedStatus: boolean
}
const initialState: NotificationsState = {
  unReadNotificationList: {
    data: [],
    hasData: false,
    isSuccess: false,
    message: '',
    subMessage: '',
    totalCount: 0,
  },

  allNotificationList: {
    data: [],
    hasData: false,
    isSuccess: false,
    message: '',
    subMessage: '',
    totalCount: 0,
  },
  newNotificationReceivedStatus: false,
}
// slice
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUnreadNotificationList: (state, action) => {
      state.unReadNotificationList = action.payload
    },
    setAllNotificationList: (state, action) => {
      state.allNotificationList = action.payload
    },
    setnewNotificationReceivedStatus: (state) => {
      state.newNotificationReceivedStatus = !state.newNotificationReceivedStatus
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const NotificationsActions = {
  setUnreadNotificationList: notificationsSlice.actions.setUnreadNotificationList,
  setAllNotificationList: notificationsSlice.actions.setAllNotificationList,
  setnewNotificationReceivedStatus : notificationsSlice.actions.setnewNotificationReceivedStatus,
}

// Selectors
export const getUnreadNotificationList = (state: RootState) =>
  state?.notifications?.unReadNotificationList
export const getAllNotificationList = (state: RootState) =>
  state?.notifications?.allNotificationList
export const getNewNotificationReceivedStatus = (state: RootState) =>
  state?.notifications?.newNotificationReceivedStatus
// Reducer
export default notificationsSlice.reducer
