import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'store/hooks'

import { selectTheme, themeActions } from '../store/themeSlice'

export type ThemeServiceOperators = {
  getTheme: string
  setTheme: (themeMode: string) => void
}

export const useThemeService = (): Readonly<ThemeServiceOperators> => {
  const dispatch = useAppDispatch()
  return {
    getTheme: useAppSelector(selectTheme),
    setTheme: useCallback(themeMode => {
      dispatch(themeActions.setTheme(themeMode))
    }, []),
  }
}

export default useThemeService
