import loadable from '@loadable/component'
import { Route, Routes } from 'react-router-dom'

import RedirectToDashBoard from './RedirectToDashBoard'
import RequireAuth from './RequireAuth'

const Layout = loadable(() => import('components/Layout'))
const AboutPage = loadable(() => import('pages/AboutPage'))
const ForgotPassword = loadable(() => import('features/account/components/ForgotPassword'))
const VerifyEmail = loadable(() => import('features/account/components/VerifyEmail'))
const ResetPassword = loadable(() => import('features/account/components/ResetPassword'))
const LoginContainer = loadable(() => import('features/account/components/LoginContainer'))
const FacilityPage = loadable(() => import('pages/FacilityPage'))
const OrganizationPage = loadable(() => import('pages/OrganizationPage'))
const MyFacility = loadable(() => import('pages/MyFacility'))
const ReferralPage = loadable(() => import('pages/ReferralPage'))
const UserManagementPage = loadable(() => import('pages/UserManagementPage'))
const RolesAndPermissionsPage = loadable(() => import('pages/RolesAndPermissionsPage'))
const Dashboard = loadable(() => import('pages/Dashboard'))
const MyProfile = loadable(() => import('features/account/components/MyProfile'))
const Settings = loadable(() => import('features/account/components/Settings'))
const DrugPage = loadable(() => import('pages/DrugPage'))
const Discussion = loadable(() => import('pages/Discussion'))
const PrivacyPolicy = loadable(() => import('pages/PrivacyPolicy'))
const NotificationPage = loadable(() => import('pages/NotifiicationsPage'))
const TaskList = loadable(() => import('features/referrals/components/TaskList/TaskListContainer'))

const AppRoutes = () => (
  <Routes>
    <Route element={<RequireAuth />}>
      <Route element={<Layout />}>
        {/* <Helmet /> */}
        <Route path="about" element={<AboutPage />} />
        <Route path="dashboard/*" element={<Dashboard />} />
        <Route path="organization/*" element={<OrganizationPage />} />
        <Route path="facility/*" element={<FacilityPage />} />
        <Route path="referral/*" element={<ReferralPage />} />
        <Route path="userManagement/*" element={<UserManagementPage />} />
        <Route path="roles/*" element={<RolesAndPermissionsPage />} />
        <Route path="myFacility" element={<MyFacility />} />
        <Route path="drugprice/*" element={<DrugPage />} />
        <Route path="discussions/*" element={<Discussion />} />
        <Route path="profile" element={<MyProfile />} />
        <Route path="settings" element={<Settings />} />
        <Route path="privacyPolicy/*" element={<PrivacyPolicy />} />

        <Route path="notifications/*" element={<NotificationPage />} />
        <Route path="task-list/*" element={<TaskList />} />

        <Route path="/*" element={<Dashboard />} />
      </Route>
    </Route>
    <Route element={<RedirectToDashBoard />}>
      <Route path="/" element={<LoginContainer />} />
      <Route path="forgotpassword" element={<ForgotPassword />} />
      <Route path="verifyemail" element={<VerifyEmail />} />
      <Route path="resetpassword" element={<ResetPassword />} />
      <Route path="restpassword/:id" element={<ResetPassword />} />
    </Route>
  </Routes>
)

export default AppRoutes
