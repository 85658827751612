import SendbirdChat from '@sendbird/chat'
import {
  GroupChannel,
  GroupChannelListQuery,
  GroupChannelListQueryParams,
  GroupChannelModule,
  SuperChannelFilter,
  UnreadChannelFilter,
} from '@sendbird/chat/groupChannel'
import { BaseMessage, MentionType, UserMessage } from '@sendbird/chat/message'
import { OpenChannelModule } from '@sendbird/chat/openChannel'
import Cookies from 'js-cookie'

import { Env } from 'config/Env'
import { ChatNotificationDetails } from 'features/discussions/types'

import { getFormatedDate } from './getFormatedDate'

export const groupChannelListenerName = 'GROUP_CHANNEL_LISTENER'
export const openChannelListenerName = 'OPEN_CHANNEL_LISTENER'

export type MetaDataProp = {
  hasAttachment: boolean
  id?: number
  size?: number
  data?: string
}

export type MessageType = {
  state: {
    body?: string
    index: number
    user?: string | null
    timestamp: string
    time: string
    metadata?: MetaDataProp
    messageId: number
    mentionList?: string[] | null
    mentionType?: MentionType | null
    customType?: string
  }
  author: boolean | undefined
}

export const sbChatClient = SendbirdChat.init({
  appId: Env.SENDBIRD_CHAT_ID ?? '',
  modules: [new GroupChannelModule(), new OpenChannelModule()],
})

export const asyncConnectUser = async (userId: string, userSessionToken: string) =>
  new Promise((resolve, reject) => {
    const MAX_RETRIES = 3
    const RETRY_DELAY_MS = 1000
    let retries = 0

    const tryConnect = async () => {
      try {
        const test = await sbChatClient.connect(userId, userSessionToken)

        resolve(test) // Resolve the promise when the connection is successful
      } catch (error) {
        retries += 1 // Increment retries explicitly

        if (retries < MAX_RETRIES) {
          // Wrap tryConnect in a non-async function and use it as the setTimeout callback
          setTimeout(() => {
            tryConnect().catch(reject) // Call tryConnect and handle any errors
          }, RETRY_DELAY_MS)
        } else {
          reject(new Error('Max retry attempts reached. Connection failed.'))
        }
      }
    }

    tryConnect() // Initial connection attempt
  })

export const getTime = (dateVal: string | number | Date) => {
  const currentTime = new Date()
  const tim = new Date(dateVal)
  const isSameDate = currentTime.toDateString() === tim.toDateString()
  const timeDifference = Math.abs(currentTime.getTime() - tim.getTime())

  if (isSameDate && timeDifference <= 3600000) {
    if (Math.round(timeDifference / 60000) === 0) {
      return 'Now'
    }
    return `${Math.round(timeDifference / 60000)} mins ago`
  }

  return getFormatedDate(true, false, tim?.toString())
}

export const getAuthor = (idParam: string, loggedUserVal: string) => idParam === loggedUserVal

export const getMetaData = (strVal: string) => {
  try {
    return JSON.parse(strVal)
  } catch (e) {
    return {
      hasAttachment: false,
      data: strVal,
    }
  }
}

export const getTimeStamp = (dateVal: number) => {
  if (dateVal) {
    return new Date(dateVal)?.toDateString()
  }
  return '0'
}

export const getStructuredMessage = (chatMessList: UserMessage[], reversed = true) => {
  const loggedInuser = Cookies.get('chat_user_id') ?? ''
  const reversedArray = [...chatMessList]
  if (reversed) {
    reversedArray.reverse()
  }
  return reversedArray?.map(item => {
    if (item?.message) {
      return {
        state: {
          body: item?.message,
          index: 1,
          user: item?.sender?.nickname,
          timestamp: getTimeStamp(item?.createdAt),
          time: getTime(item?.createdAt),
          metadata: getMetaData(item?.data),
          messageId: item?.messageId,
          mentionList: item?.mentionedUserIds,
          mentionType: item?.mentionType,
          customType: item?.customType,
        },
        author: getAuthor(item?.sender?.userId, loggedInuser),
      }
    }
    return null
  })
}

export type MetaDataType = {
  facility_name?: string
  referral_name?: string
  referral_Id?: string
  patient_id: number
  patient_facility_id?: number
}

export const getFacilityName = (cData: unknown) => {
  const changedData = cData as MetaDataType
  return changedData?.facility_name
}

export const getReferralId = (cData: unknown) => {
  const changedData = cData as MetaDataType
  return changedData?.referral_Id
}
export const getPatientId = (cData: unknown) => {
  const changedData = cData as MetaDataType
  return changedData?.patient_facility_id ?? 0
}

export const getPatientReferralId = (cData: unknown) => {
  const changedData = cData as MetaDataType
  return changedData?.patient_id ?? 0
}
export const getChannelName = (cData: unknown) => {
  const changedData = cData as MetaDataType
  return changedData?.referral_name
}

export const getTimeStamps = (lastMessageData?: BaseMessage | null) => {
  const lastMess = lastMessageData as UserMessage
  return getTime(lastMess?.createdAt)
}

export const asyncGetUnreadChatList = async (
  setChatNotifFunc: (arg0: ChatNotificationDetails[]) => void,
  customFilterArray: string[],
) => {
  const params: GroupChannelListQueryParams = {
    includeEmpty: true,
    includeFrozen: true,
    limit: 5,
    unreadChannelFilter: UnreadChannelFilter.ALL,
    customTypesFilter: customFilterArray?.length ? customFilterArray : undefined,
    superChannelFilter: SuperChannelFilter.NON_SUPER,
  }
  const query: GroupChannelListQuery =
    sbChatClient.groupChannel.createMyGroupChannelListQuery(params)
  const channels: GroupChannel[] = await query.next()
  if (channels) {
    const notificationBody = channels?.map(item => ({
      patientName: getChannelName(item?.cachedMetaData) ?? '',
      facilityName: getFacilityName(item?.cachedMetaData) ?? '',
      referralId: getReferralId(item?.cachedMetaData) ?? '',
      timeStamp: getTimeStamps(item?.lastMessage) ?? '',
      threadId: item?.url ?? '',
      patientId: getPatientId(item?.cachedMetaData),
      unReadCount: item?.unreadMessageCount,
    }))
    setChatNotifFunc(notificationBody)
  }
}

export const getAsyncDisconnectChat = async () => {
  try {
    Cookies.remove('gcc_chat_token')
    Cookies.remove('chat_user_id')
    await sbChatClient.disconnect()
  } catch {
    Cookies.remove('gcc_chat_token')
    Cookies.remove('chat_user_id')
  }
}

export const retryJoinOpenChannel = async () => {
  const channelId = Env.NOTIFICATION_CHANNEL_ID ?? ''
  const channel = await sbChatClient.openChannel.getChannel(channelId)
  channel.enter()
}
export const asyncJoinOpenChannel = async () => {
  try {
    const channelId = Env.NOTIFICATION_CHANNEL_ID ?? ''
    const channel = await sbChatClient.openChannel.getChannel(channelId)
    channel.enter()
    const toDoListChannelId = Env.TASKLIST_CHANNEL_ID ?? ''
    const toDoChannel = await sbChatClient.openChannel.getChannel(toDoListChannelId)
    toDoChannel.enter()
  } catch (error) {
    retryJoinOpenChannel()
  }
}

export const getUnreadChatCountSb = async (
  setUnreadCountFunc: (count: number) => void,
  customFilArray: string[],
) => {
  const params = {
    channelCustomTypesFilter: customFilArray?.length ? customFilArray : undefined,
  }

  if (!customFilArray?.length) {
    const count = await sbChatClient.groupChannel.getTotalUnreadMessageCount()
    setUnreadCountFunc(count)
  } else {
    const filteredCount = await sbChatClient.groupChannel.getTotalUnreadMessageCount(params)
    setUnreadCountFunc(filteredCount)
  }
}
