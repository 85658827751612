import axios from 'axios'
import i18next from 'i18next'

import Snackbar from 'components/Snackbar'

import { logApiException, logApiRequestFailed } from './sentryServices/logApiInfo'

export const showSnackbar = (isSuccess: boolean, content: string) => {
  if (isSuccess) {
    Snackbar.success(content)
  } else {
    Snackbar.error(content)
  }
}

export const showInfoSnackbar = (content: string) => {
  Snackbar.info(content)
}

export const showApiFailedSnackbar = (message?: string) => {
  Snackbar.error(i18next.t('notifications.internal_server_error'))
  logApiRequestFailed(message ?? '')
}

export const showExceptionSnackbar = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    Snackbar.error(i18next.t('notifications.internal_server_error'))
    logApiException(error)
  } else {
    Snackbar.error(i18next.t('notifications.network_error'))
  }
}
