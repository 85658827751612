import { SagaIterator } from '@redux-saga/core'
import { call, takeEvery } from 'redux-saga/effects'

import { organizationCreate } from 'features/organization/api'
import { organizationActions } from 'features/organization/store/organization.slice'
import { OrganizationDetailsRequest} from 'features/organization/types'


function* onCreateOrganization({
  payload,
}: {
  type: typeof  organizationActions.organizationCreation
  payload: OrganizationDetailsRequest
}): SagaIterator 
{
  try
  {
    const res =yield call(organizationCreate,payload)
    console.log(res,"ress")
  }
  catch(error)
  {
    console.log(error,"err");

  }
}

// Watcher Saga
export function* organizationWatcherSaga(): SagaIterator {
  yield takeEvery(organizationActions.organizationCreation.type, onCreateOrganization)
}
  
export default organizationWatcherSaga
