import moment from 'moment'

export const getFormatedDate = (hasTime: boolean, needLocalize: boolean, date?: string) => {
  if (date === undefined || date === null) return ''
  const format = hasTime ? 'MM/DD/YYYY h:mm A' : 'MM/DD/YYYY'
  return needLocalize ? moment.utc(date)?.local()?.format(format) : moment.utc(date)?.format(format)
}

export const getFormatedDateWithoutTimezone = (date: string) => {
  const value = new Date(date)
  const month =
    (parseInt(value.getMonth().toString(), 10) + 1).toString().length === 1
      ? `0${(parseInt(value.getMonth().toString(), 10) + 1).toString()}`
      : parseInt(value.getMonth().toString(), 10) + 1

  const day = value.getDate().toString().length === 1 ? `0${value.getDate()}` : value.getDate()

  const fullYear = value.getFullYear()
  // appends 'T00:00:00.000' for removing time and time zone
  return `${fullYear}-${month}-${day}T00:00:00.000Z` as unknown as Date
}
export const removeTimeFromDate = (date: Date) => date.toString().split('T')[0] as unknown as Date

export const extractTimeFromDate = (date: string): string =>
  new Date(date).toLocaleTimeString('en-US', { hour12: false })

export const calculateAge = (dob: Date) => {
  const birthDate = moment(dob)
  const currentDate = moment()
  return currentDate.diff(birthDate, 'years')
}
