import { useCallback } from 'react'

import { useAppSelector, useAppDispatch } from 'store/hooks'
import { Envelope } from 'utils/types/Envelope'

import {
  CardResponse,
  CardReferral,
  ThreadDetails,
  CreateThreadData,
  ThreadCreateResponse,
  PatiendDiscussionsStatusResponse,
  CreateBulkDiscussionsRequestData,
  CreateBulkDiscussionsResponse,
  BroadcastMessageObject,
  BroadcastMessageResponse,
} from '../../referrals/types'
import {
  getRefLists,
  createThread,
  getReferralSourcePdf,
  sendReferralChatAttachment,
  deleteChatAttachment,
  getChatSessionToken,
  getReferralHeader,
  getPatientDiscussionStatus,
  createBulkDiscussions,
  sendBroadcastMessage,
  isParticipant,
  addUserToDiscussion,
  getDiscussionMemberList,
  reValidateDiscussionParticipants,
} from '../api'
import {
  discussionActions,
  getSelectedThread,
  getNewChannelId,
  getNewChatDetails,
  getDeletedChatDetails,
  getChatNotificationList,
  getUnreadChatThreadCount,
  getNewMemberDetails,
} from '../store'
import {
  ChatDetails,
  ChatMemberDetails,
  ChatNotificationDetails,
  DeleteChatDetails,
  DiscussionHeaderDataType,
  DiscussionMemberListReqBody,
  DiscussionMemberListResBody,
  IsParticipantReqBody,
  UserToDiscussionReqBody,
  RevalidationReqBody,
} from '../types'

export type DiscussionServiceOperators = {
  getReferralList: (data: CardReferral) => Promise<CardResponse>
  createThreadCall: (data: CreateThreadData) => Promise<ThreadCreateResponse>
  getThreadSourceDoc: (id: number) => Promise<Envelope>
  getReferralHeader: (id: number) => Promise<{ data: DiscussionHeaderDataType }>
  sendChatAttachment: (data: FormData) => Promise<Envelope>
  setNewChannelId: (data: string) => void
  getNewChannelId: string
  getSelectedThreadID: ThreadDetails
  setSelectedThreadDetails: (selectedthread?: ThreadDetails) => void
  deleteChatAttachment: (id: number) => Promise<Envelope>
  getChatSessionToken: () => Promise<Envelope>
  setNewChatDetails: (details: ChatDetails) => void
  setNewMemberDetails: (details: ChatMemberDetails) => void
  setDeletedChatDetails: (details: DeleteChatDetails) => void
  setChatNotificationList: (details: ChatNotificationDetails[]) => void
  setUnreadChatThreadCount: (count: number) => void
  getNewChatDetails: ChatDetails | null
  getNewMemberDetails: ChatMemberDetails | null
  getDeletedChatDetails: DeleteChatDetails | null
  getChatNotificationList: ChatNotificationDetails[]
  getUnreadChatThreadCount: number
  getPatientDiscussionsStatus: (patientId: number) => Promise<PatiendDiscussionsStatusResponse>
  createBulkDiscussions: (
    data: CreateBulkDiscussionsRequestData,
  ) => Promise<CreateBulkDiscussionsResponse>
  sendBroadcastMessage: (data: BroadcastMessageObject) => Promise<BroadcastMessageResponse>
  checkIsParticipant: (data: IsParticipantReqBody) => Promise<Envelope>
  addUserToDiscussion: (data: UserToDiscussionReqBody) => Promise<Envelope>
  getDiscussionMemberList: (
    data: DiscussionMemberListReqBody,
  ) => Promise<DiscussionMemberListResBody>
  reValidateDiscussionParticipants: (data: RevalidationReqBody) => Promise<Envelope>
}

export const useDiscussionService = (): Readonly<DiscussionServiceOperators> => {
  const dispatch = useAppDispatch()
  return {
    sendChatAttachment: useCallback((data: FormData) => sendReferralChatAttachment(data), []),

    getReferralList: useCallback((data: CardReferral) => getRefLists(data), []),

    createThreadCall: useCallback((data: CreateThreadData) => createThread(data), []),

    getReferralHeader: useCallback((id: number) => getReferralHeader(id), []),

    getThreadSourceDoc: useCallback((id: number) => getReferralSourcePdf(id), []),
    getSelectedThreadID: useAppSelector(getSelectedThread) ?? '',
    setSelectedThreadDetails: useCallback(selectedthread => {
      dispatch(discussionActions.setSelectedThreadId(selectedthread))
    }, []),
    getNewChannelId: useAppSelector(getNewChannelId) ?? '',
    setNewChannelId: useCallback(chatId => {
      dispatch(discussionActions.setNewChannelId(chatId))
    }, []),
    deleteChatAttachment: useCallback((id: number) => deleteChatAttachment(id), []),
    getChatSessionToken: useCallback(() => getChatSessionToken(), []),

    setNewChatDetails: useCallback(details => {
      dispatch(discussionActions.setNewChatDetails(details))
    }, []),
    setNewMemberDetails: useCallback(details => {
      dispatch(discussionActions.setNewMemberDetails(details))
    }, []),
    setDeletedChatDetails: useCallback(details => {
      dispatch(discussionActions.setDeletedChatDetails(details))
    }, []),
    setChatNotificationList: useCallback(details => {
      dispatch(discussionActions.setChatNotificationList(details))
    }, []),
    getNewChatDetails: useAppSelector(getNewChatDetails),
    getNewMemberDetails: useAppSelector(getNewMemberDetails),
    getDeletedChatDetails: useAppSelector(getDeletedChatDetails),
    getChatNotificationList: useAppSelector(getChatNotificationList),
    setUnreadChatThreadCount: useCallback(count => {
      dispatch(discussionActions.setUnreadChatThreadCount(count))
    }, []),
    getUnreadChatThreadCount: useAppSelector(getUnreadChatThreadCount),
    getPatientDiscussionsStatus: useCallback(
      (patientId: number) => getPatientDiscussionStatus(patientId),
      [],
    ),
    createBulkDiscussions: useCallback(
      (data: CreateBulkDiscussionsRequestData) => createBulkDiscussions(data),
      [],
    ),
    sendBroadcastMessage: useCallback(
      (data: BroadcastMessageObject) => sendBroadcastMessage(data),
      [],
    ),
    checkIsParticipant: useCallback((data: IsParticipantReqBody) => isParticipant(data), []),
    getDiscussionMemberList: useCallback(
      (data: DiscussionMemberListReqBody) => getDiscussionMemberList(data),
      [],
    ),
    addUserToDiscussion: useCallback(
      (data: UserToDiscussionReqBody) => addUserToDiscussion(data),
      [],
    ),
    reValidateDiscussionParticipants: useCallback(
      (data: RevalidationReqBody) => reValidateDiscussionParticipants(data),
      [],
    ),
  }
}

export default useDiscussionService
