// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { LoginFormInput, LoginUserInfo, Profile, SourceHospital } from 'features/account/types'
import type { RootState } from 'store/store'

export interface LoginState {
  loginDetails: LoginUserInfo
  permissionIds: number[]
  menuIds: number[]
  selectedRoleLevel: number
  selectedFacilities: number[]
  profileDetails: Profile
  sourceHospitalScreen: number
  sourceHospitalList: SourceHospital[]
}

const initialState: LoginState = {
  loginDetails: {},
  permissionIds: [],
  menuIds: [],
  selectedRoleLevel: 0,
  selectedFacilities: [],
  profileDetails: {},
  sourceHospitalScreen: 0,
  sourceHospitalList: [],
}

// slice
export const loginSlice = createSlice({
  name: 'loginDetails',
  initialState,
  reducers: {
    loginSucceeded(state, action: PayloadAction<LoginUserInfo>) {
      state.loginDetails = action.payload
    },
    clearLoginDetails(state) {
      state.loginDetails = {}
    },
    setPermissionIds: (state, action) => {
      state.permissionIds = action.payload
    },
    setMenuIds: (state, action) => {
      state.menuIds = action.payload
    },
    setSelectedRoleLevel: (state, action) => {
      state.selectedRoleLevel = action.payload
    },
    setSelectedFacilities: (state, action) => {
      state.selectedFacilities = action.payload
    },
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload
    },
    setDiscussionNotif: (state, action) => {
      state.loginDetails.showDiscussion = action.payload
    },
    setSourceHospitalScreen: (state, action) => {
      state.sourceHospitalScreen = action.payload
    },
    setSourceHospitalList: (state, action) => {
      state.sourceHospitalList = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const loginActions = {
  login: createAction(`${loginSlice.name}/login`, (loginData: LoginFormInput) => ({
    payload: {
      username: loginData.username,
      password: loginData.password,
      grant_type: 'password',
      scope: 'api1',
      client_id: 'web',
      client_secret: 'secret',
    },
  })),
  loginSucceeded: loginSlice.actions.loginSucceeded,
  clearLoginDetails: loginSlice.actions.clearLoginDetails,
  setPermissionIds: loginSlice.actions.setPermissionIds,
  setMenuIds: loginSlice.actions.setMenuIds,
  setSelectedRoleLevel: loginSlice.actions.setSelectedRoleLevel,
  setSelectedFacilities: loginSlice.actions.setSelectedFacilities,
  setProfileDetails: loginSlice.actions.setProfileDetails,
  setDiscussionNotif: loginSlice.actions.setDiscussionNotif,
  setSourceHospitalScreen: loginSlice.actions.setSourceHospitalScreen,
  setSourceHospitalList: loginSlice.actions.setSourceHospitalList,
}

// Selectors
export const loginDetails = (state: RootState) => state.loginDetails.loginDetails
export const getPermissionIds = (state: RootState) => state.loginDetails.permissionIds
export const getMenuIds = (state: RootState) => state.loginDetails.menuIds
export const getSelectedRoleLevel = (state: RootState) => state.loginDetails.selectedRoleLevel
export const getSelectedFacilities = (state: RootState) => state.loginDetails.selectedFacilities
export const getProfileDetails = (state: RootState) => state.loginDetails.profileDetails
export const getSourceHospitalScreen = (state: RootState) => state.loginDetails.sourceHospitalScreen
export const getSourceHospitalList = (state: RootState) => state.loginDetails.sourceHospitalList

// Reducer
export default loginSlice.reducer
