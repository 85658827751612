import Close from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { useSnackbar, OptionsObject, WithSnackbarProps, SnackbarKey } from 'notistack'

export enum VariantType {
  default = 'default',
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
}

interface SnackProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void
}

const InnerSnackbarUtilsConfigurator: React.FC<SnackProps> = props => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

let useSnackbarRef: WithSnackbarProps
let closeSnackbar: (key: string | number) => void
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp
  closeSnackbar = useSnackbarRefProp?.closeSnackbar
}

export const SnackbarUtilsConfigurator = (props: { children?: React.ReactNode }) => (
  <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef}>
    {props.children}
  </InnerSnackbarUtilsConfigurator>
)

// sets a default length for all Snack messages
const defaultSnackMessageLength = 1000

const trimMessage = (msg: string, length: number = defaultSnackMessageLength) =>
  msg?.substring(0, length)

const closeAction = (key: SnackbarKey) => (
  <IconButton onClick={() => closeSnackbar(key)}>
    <Close sx={{ color: 'white' }} />
  </IconButton>
)

export default {
  success(msg: string, options: OptionsObject = {}) {
    this.toast(trimMessage(msg), {
      ...options,
      variant: VariantType.success,
    })
  },
  warning(msg: string, options: OptionsObject = {}) {
    this.toast(trimMessage(msg), {
      ...options,
      variant: VariantType.warning,
    })
  },
  info(msg: string, options: OptionsObject = {}) {
    this.toast(trimMessage(msg), {
      ...options,
      variant: VariantType.info,
    })
  },
  error(msg: string, options: OptionsObject = {}) {
    this.toast(trimMessage(msg), {
      ...options,
      variant: VariantType.error,
    })
  },
  toast(msg: string, options: OptionsObject = {}) {
    useSnackbarRef?.enqueueSnackbar(msg, { ...options, action: key => closeAction(key) })
  },
}
