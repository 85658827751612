// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import {
  OrganizationDetailsFormInput,
  OrganizationList,
  QuestionBankListDetail,
  QuestionBankDetailResponse,
  WordBankList,
  WordBankSelectedDeatil,
} from 'features/organization/types'
import type { RootState } from 'store/store'

export interface OrganizationState {
  organizationDetails: OrganizationDetailsFormInput
  organizationList: OrganizationList
  featureList: object[]
  questionBankListDetail: QuestionBankListDetail
  questionList: QuestionBankDetailResponse
  selectedDetail: WordBankSelectedDeatil
  wordBankList: WordBankList
  selectedOrganization: { id: number; name: string }
  activeTab: number
}

const initialState: OrganizationState = {
  organizationDetails: {},
  organizationList: { data: [], totalCount: 0, hasData: true },
  featureList: [],
  questionBankListDetail: {},
  questionList: {},
  selectedDetail: {},
  wordBankList: { data: [], totalCount: 0, hasData: true },
  selectedOrganization: { id: 0, name: '' },
  activeTab: 0,
}

// slice
export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationList: (state, action) => {
      state.organizationList = action.payload
    },
    setFeaturesList: (state, action) => {
      state.featureList = action.payload
    },
    setQuestionBankListDetail: (state, action) => {
      state.questionBankListDetail = action.payload
    },
    setQuestionList: (state, action) => {
      state.questionList = action.payload
    },
    setWordBankSelectedDetail: (state, action) => {
      state.selectedDetail = action.payload
    },
    setWordBankList: (state, action) => {
      state.wordBankList = action.payload
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const organizationActions = {
  organizationCreation: createAction(
    `${organizationSlice.name}/organizationCreation`,
    (organizationData: OrganizationDetailsFormInput) => ({
      payload: organizationData,
    }),
  ),
  setOrganizationList: organizationSlice.actions.setOrganizationList,
  setFeaturesList: organizationSlice.actions.setFeaturesList,
  setQuestionBankListDetail: organizationSlice.actions.setQuestionBankListDetail,
  setQuestionList: organizationSlice.actions.setQuestionList,
  setSelectedDetail: organizationSlice.actions.setWordBankSelectedDetail,
  setWordBankList: organizationSlice.actions.setWordBankList,
  setSelectedOrganizationDetail: organizationSlice.actions.setSelectedOrganization,
  setActiveTab: organizationSlice.actions.setActiveTab,
}

// Selectors
export const organizationDetails = (state: RootState) => state.organization.organizationDetails
export const selectOrganizationList = (state: RootState) => state.organization.organizationList
export const selectFeaturesList = (state: RootState) => state.organization.featureList
export const selectQuestionBankDetail = (state: RootState) =>
  state.organization.questionBankListDetail
export const selectQuestionList = (state: RootState) => state.organization.questionList
export const selectedOrganization = (state: RootState) => state.organization.selectedOrganization
export const getActiveTab = (state: RootState) => state.organization.activeTab

// Reducer
export default organizationSlice.reducer
