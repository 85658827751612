import Cookies from 'js-cookie'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const RedirectToDashBoard = () => {
  const auth = !!Cookies.get('gcc_access_token')
  const location = useLocation()
  if (auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/dashboard/view" state={{ from: location }} />
  }

  return <Outlet />
}

export default RedirectToDashBoard
