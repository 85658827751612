// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from 'store/store'
import { DropDownResponse } from 'utils/types/DropDownResponse'

import {
  Categories,
  KeywordDetail,
  Referral,
  ReferralList,
  ReferralListType,
  WaystarMatchRequest,
  ReferralFilters,
  ReferralListInputsParams,
  ReferralListTypeInputs,
  ChildReferralFilters,
  DenialResponse,
  CategoryResponse,
  PVerifyLinks,
  PVerifyMatchRequest,
  ChildReferralFiltersProps,
  ParentReferralFiltersProps,
  ClearIndividualReferralProps,
  GridStatusFiltersProps,
} from '../types'

export interface ReferralState {
  referralDetail: Referral
  referralList: ReferralList
  selectedKeyword: KeywordDetail[]
  allKeyword: KeywordDetail[]
  isQuestionAnswersLoaded: boolean
  categoryDetailList: Categories[]
  selectedReferral: number
  selectedListTab: number
  referralListRoot: ReferralListType
  routeList: DropDownResponse[]
  organizationList: DropDownResponse[]
  parentFilterItems: ReferralFilters
  childFilterItems: ChildReferralFilters
  refreshForSourceMessageData: boolean
  sourceMessageTabToSet: number
  referralListInputs: ReferralListTypeInputs
  isTaskListPending: boolean
  selectedParentReferral: number
  waystarValidationChanged: boolean
  holdReasons: DropDownResponse[]
  lostReasons: DropDownResponse[]
  denialReasonList: DenialResponse[]
  referralGridViewList: CategoryResponse[]
  facilityListForParentFilter: DropDownResponse[]
  facilityListForChildFilter: DropDownResponse[]
  insuranceProvider: number
  pVerifyLinks: PVerifyLinks
  pVerifyRequestId: number
  pVerifyValidationChanged: boolean
  selectedParentTab: number
  faxStatusRefresh: boolean
  sourceHospitalList: DropDownResponse[]
  refreshRefList: boolean
}

const getReferralListDefaultInputData: ReferralListInputsParams = {
  search: '',
  sort: '',
  pageNumber: 1,
  pageSize: 10,
}

const initialState: ReferralState = {
  referralDetail: {},
  referralList: { data: [], totalCount: 0, hasData: true },
  selectedKeyword: [],
  allKeyword: [],
  isQuestionAnswersLoaded: false,
  categoryDetailList: [],
  selectedReferral: 0,
  selectedParentReferral: 0,
  selectedListTab: 1,
  referralListRoot: 'table',
  routeList: [],
  organizationList: [],
  parentFilterItems: {
    organizations: [],
    routes: [],
    facilities: [],
    status: [],
    sourceHospitals: [],
    createdDate: '',
    admissionDate: '',
  },
  childFilterItems: {
    organizations: [],
    routes: [],
    facilities: [],
    activeListStatus: [],
    matchedListStatus: [],
    myReferralListStatus: [],
    holdReasons: [],
    lostReasons: [],
    assignedReferralListStatus: [],
    sourceHospitals: [],
    createdDate: '',
    admissionDate: '',
    processingStatuses: [],
    pendingDecisionStatuses: [],
    acceptedStatuses: [],
    denialStatuses: [],
  },
  refreshForSourceMessageData: false,
  sourceMessageTabToSet: 0,
  isTaskListPending: false,
  referralListInputs: {
    matched: getReferralListDefaultInputData,
    unMatched: getReferralListDefaultInputData,
    hold: getReferralListDefaultInputData,
    lost: getReferralListDefaultInputData,
    myReferral: getReferralListDefaultInputData,
    parentReferral: getReferralListDefaultInputData,
    active: getReferralListDefaultInputData,
    all: getReferralListDefaultInputData,
    fax: getReferralListDefaultInputData,
    assignedReferrals: getReferralListDefaultInputData,
    patientFax: getReferralListDefaultInputData,
  },
  waystarValidationChanged: false,
  holdReasons: [],
  lostReasons: [],
  denialReasonList: [],
  referralGridViewList: [],
  facilityListForParentFilter: [],
  facilityListForChildFilter: [],
  insuranceProvider: 0,
  pVerifyLinks: {
    primary: null,
    secondary: null,
    tertiary: null,
  },
  pVerifyRequestId: 0,
  pVerifyValidationChanged: false,
  selectedParentTab: 1,
  faxStatusRefresh: false,
  sourceHospitalList: [],
  refreshRefList: false,
}
// slice
export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    setReferralDetail: (state, action) => {
      state.referralDetail = action.payload
    },
    setReferralList: (state, action) => {
      state.referralList = action.payload
    },
    setSelectedKeyword: (state, action) => {
      state.selectedKeyword = action.payload
    },
    setAllKeyword: (state, action) => {
      state.allKeyword = action.payload
    },
    setIsQuestionAnswersLoaded: (state, action) => {
      state.isQuestionAnswersLoaded = action.payload
    },
    setCategoryDetailList: (state, action) => {
      state.categoryDetailList = action.payload
    },
    setWaystarMatch: (state, action) => {
      const waystarInsuranceInfo = action.payload as WaystarMatchRequest

      if (
        waystarInsuranceInfo.payerType === 1 &&
        state.referralDetail.waystarInsurance &&
        state.referralDetail.insurance
      ) {
        state.referralDetail.insurance.primaryPayerCode = waystarInsuranceInfo.payerCode
        state.referralDetail.insurance.primaryPayerName = waystarInsuranceInfo.payerName
        state.referralDetail.waystarInsurance.primaryPayerCode = waystarInsuranceInfo.payerCode
        state.referralDetail.waystarInsurance.primaryPayerName = waystarInsuranceInfo.payerName
        state.referralDetail.insurance.primaryWaystarInfo =
          waystarInsuranceInfo?.waystarValidateInfo
        state.referralDetail.waystarInsurance.primaryWaystarInfo =
          waystarInsuranceInfo?.waystarValidateInfo
        if (state.referralDetail.pVerifyInsurance) {
          state.referralDetail.pVerifyInsurance.primaryPayerCode = undefined
          state.referralDetail.pVerifyInsurance.primaryPayerName = undefined
        }
      }
      if (
        waystarInsuranceInfo.payerType === 2 &&
        state.referralDetail.insurance &&
        state.referralDetail.waystarInsurance
      ) {
        state.referralDetail.insurance.secondaryPayerCode = waystarInsuranceInfo.payerCode
        state.referralDetail.insurance.secondaryPayerName = waystarInsuranceInfo.payerName

        state.referralDetail.insurance.secondaryWaystarInfo =
          waystarInsuranceInfo?.waystarValidateInfo
        state.referralDetail.waystarInsurance.secondaryPayerCode = waystarInsuranceInfo.payerCode
        state.referralDetail.waystarInsurance.secondaryPayerName = waystarInsuranceInfo.payerName

        state.referralDetail.waystarInsurance.secondaryWaystarInfo =
          waystarInsuranceInfo?.waystarValidateInfo
        if (state.referralDetail.pVerifyInsurance) {
          state.referralDetail.pVerifyInsurance.secondaryPayerCode = undefined
          state.referralDetail.pVerifyInsurance.secondaryPayerName = undefined
        }
      }
      if (
        waystarInsuranceInfo.payerType === 3 &&
        state.referralDetail.insurance &&
        state.referralDetail.waystarInsurance
      ) {
        state.referralDetail.insurance.tertiaryPayerCode = waystarInsuranceInfo.payerCode
        state.referralDetail.insurance.tertiaryPayerName = waystarInsuranceInfo.payerName

        state.referralDetail.insurance.tertiaryWaystarInfo =
          waystarInsuranceInfo?.waystarValidateInfo
        state.referralDetail.waystarInsurance.tertiaryPayerCode = waystarInsuranceInfo.payerCode
        state.referralDetail.waystarInsurance.tertiaryPayerName = waystarInsuranceInfo.payerName

        state.referralDetail.waystarInsurance.tertiaryWaystarInfo =
          waystarInsuranceInfo?.waystarValidateInfo
        if (state.referralDetail.pVerifyInsurance) {
          state.referralDetail.pVerifyInsurance.tertiaryPayerCode = undefined
          state.referralDetail.pVerifyInsurance.tertiaryPayerName = undefined
        }
      }
    },
    setPVerifyMatch: (state, action) => {
      const pVerifyMatchInfo = action.payload as PVerifyMatchRequest

      if (
        pVerifyMatchInfo.payerType === 1 &&
        state.referralDetail.insurance &&
        state.referralDetail.pVerifyInsurance
      ) {
        state.referralDetail.insurance.primaryPayerCode = pVerifyMatchInfo.payerCode
        state.referralDetail.insurance.primaryPayerName = pVerifyMatchInfo.payerName
        state.referralDetail.pVerifyInsurance.primaryPayerCode = pVerifyMatchInfo.payerCode
        state.referralDetail.pVerifyInsurance.primaryPayerName = pVerifyMatchInfo.payerName
        if (state.referralDetail.waystarInsurance) {
          state.referralDetail.waystarInsurance.primaryPayerCode = undefined
          state.referralDetail.waystarInsurance.primaryPayerName = undefined
          state.referralDetail.waystarInsurance.primaryWaystarInfo = undefined
        }
      }
      if (
        pVerifyMatchInfo.payerType === 2 &&
        state.referralDetail.insurance &&
        state.referralDetail.pVerifyInsurance
      ) {
        state.referralDetail.insurance.secondaryPayerCode = pVerifyMatchInfo.payerCode
        state.referralDetail.insurance.secondaryPayerName = pVerifyMatchInfo.payerName
        state.referralDetail.pVerifyInsurance.secondaryPayerCode = pVerifyMatchInfo.payerCode
        state.referralDetail.pVerifyInsurance.secondaryPayerName = pVerifyMatchInfo.payerName
        if (state.referralDetail.waystarInsurance) {
          state.referralDetail.waystarInsurance.secondaryPayerCode = undefined
          state.referralDetail.waystarInsurance.secondaryPayerName = undefined
          state.referralDetail.waystarInsurance.secondaryWaystarInfo = undefined
        }
      }
      if (
        pVerifyMatchInfo.payerType === 3 &&
        state.referralDetail.insurance &&
        state.referralDetail.pVerifyInsurance
      ) {
        state.referralDetail.insurance.tertiaryPayerCode = pVerifyMatchInfo.payerCode
        state.referralDetail.insurance.tertiaryPayerName = pVerifyMatchInfo.payerName
        state.referralDetail.pVerifyInsurance.tertiaryPayerCode = pVerifyMatchInfo.payerCode
        state.referralDetail.pVerifyInsurance.tertiaryPayerName = pVerifyMatchInfo.payerName
        if (state.referralDetail.waystarInsurance) {
          state.referralDetail.waystarInsurance.tertiaryPayerCode = undefined
          state.referralDetail.waystarInsurance.tertiaryPayerName = undefined
          state.referralDetail.waystarInsurance.tertiaryWaystarInfo = undefined
        }
      }
    },
    setSelectedReferral: (state, action) => {
      state.selectedReferral = action.payload
    },
    setSelectedParentReferral: (state, action) => {
      state.selectedParentReferral = action.payload
    },

    setSelectedListTab: (state, action) => {
      state.selectedListTab = action.payload
    },
    setReferralListRoot: (state, action) => {
      state.referralListRoot = action.payload
    },
    setRouteList: (state, action) => {
      state.routeList = action.payload
    },
    setSourceHospitalList: (state, action) => {
      state.sourceHospitalList = action.payload
    },

    setOrganizationList: (state, action) => {
      state.organizationList = action.payload
    },
    setParentFilterItems: (state, action) => {
      const data = action.payload as ParentReferralFiltersProps
      switch (data.type) {
        case 'parentReferral':
          state.parentFilterItems.organizations = data.organizations
          state.parentFilterItems.facilities = data.facilities
          state.parentFilterItems.routes = data.routes
          state.parentFilterItems.status = data.status
          state.parentFilterItems.sourceHospitals = data.sourceHospitals
          state.parentFilterItems.createdDate = data.createdDate
          state.parentFilterItems.admissionDate = data.admissionDate
          break
        case 'patientFax':
          state.parentFilterItems.organizations = data.organizations
          state.parentFilterItems.facilities = data.facilities
          state.parentFilterItems.sourceHospitals = data.sourceHospitals
          state.parentFilterItems.createdDate = data.createdDate
          state.parentFilterItems.admissionDate = data.admissionDate
          state.parentFilterItems.routes = data.routes
          break
        default:
          break
      }
    },
    setChildFilterItems: (state, action) => {
      const data = action.payload as ChildReferralFiltersProps
      switch (data.type) {
        case 'matched':
          state.childFilterItems.organizations = data.organizations ?? []
          state.childFilterItems.facilities = data.facilities ?? []
          state.childFilterItems.routes = data.routes ?? []
          state.childFilterItems.matchedListStatus = data.statusList ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          break
        case 'myReferral':
          state.childFilterItems.organizations = data.organizations ?? []
          state.childFilterItems.facilities = data.facilities ?? []
          state.childFilterItems.routes = data.routes ?? []
          state.childFilterItems.myReferralListStatus = data.statusList ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          break
        case 'activeReferral':
          state.childFilterItems.organizations = data.organizations ?? []
          state.childFilterItems.facilities = data.facilities ?? []
          state.childFilterItems.routes = data.routes ?? []
          state.childFilterItems.activeListStatus = data.statusList ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          break
        case 'assignedReferrals':
          state.childFilterItems.organizations = data.organizations ?? []
          state.childFilterItems.facilities = data.facilities ?? []
          state.childFilterItems.routes = data.routes ?? []
          state.childFilterItems.assignedReferralListStatus = data.statusList ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          break
        case 'hold':
          state.childFilterItems.organizations = data.organizations ?? []
          state.childFilterItems.facilities = data.facilities ?? []
          state.childFilterItems.routes = data.routes ?? []
          state.childFilterItems.holdReasons = data.holdReasons ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          break
        case 'lost':
          state.childFilterItems.organizations = data.organizations ?? []
          state.childFilterItems.facilities = data.facilities ?? []
          state.childFilterItems.routes = data.routes ?? []
          state.childFilterItems.lostReasons = data.lostReasons ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          break
        case 'unmatched':
          state.childFilterItems.routes = data.routes ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          break
        case 'fax':
          state.childFilterItems.organizations = data.organizations ?? []
          state.childFilterItems.facilities = data.facilities ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          state.childFilterItems.routes = data.routes ?? []
          break
        default:
          state.childFilterItems.organizations = data.organizations ?? []
          state.childFilterItems.facilities = data.facilities ?? []
          state.childFilterItems.routes = data.routes ?? []
          state.childFilterItems.sourceHospitals = data.sourceHospitals
          state.childFilterItems.createdDate = data.createdDate
          state.childFilterItems.admissionDate = data.admissionDate
          break
      }
    },

    setGridStatusFilterItems: (state, action) => {
      const data = action.payload as GridStatusFiltersProps
      switch (data.type) {
        case 1:
          state.childFilterItems.processingStatuses = data.statuses ?? []
          break
        case 2:
          state.childFilterItems.pendingDecisionStatuses = data.statuses ?? []
          break
        case 3:
          state.childFilterItems.acceptedStatuses = data.statuses ?? []
          break
        case 4:
          state.childFilterItems.denialStatuses = data.statuses ?? []
          break
        default:
          break
      }
    },
    clearIndividualParentFilter: (state, action) => {
      const data = action.payload as ClearIndividualReferralProps
      switch (data.filterId) {
        case 1:
          state.parentFilterItems.organizations = []
          state.parentFilterItems.facilities = []
          break
        case 2:
          state.parentFilterItems.facilities = []
          break
        case 3:
          state.parentFilterItems.sourceHospitals = []
          break
        case 4:
          state.parentFilterItems.admissionDate = ''
          break
        case 5:
          state.parentFilterItems.status = []
          break
        case 6:
          state.parentFilterItems.routes = []
          break
        case 7:
          state.parentFilterItems.createdDate = ''
          break
        default:
          break
      }
    },
    clearIndividualChildFilter: (state, action) => {
      const data = action.payload as ClearIndividualReferralProps
      switch (data.filterId) {
        case 1:
          state.childFilterItems.organizations = []
          state.childFilterItems.facilities = []
          break
        case 2:
          state.childFilterItems.facilities = []
          break
        case 3:
          state.childFilterItems.sourceHospitals = []
          break
        case 4:
          state.childFilterItems.admissionDate = ''
          break
        case 5:
          switch (data.type) {
            case 'matched':
              state.childFilterItems.matchedListStatus = []
              break
            case 'myReferral':
              state.childFilterItems.myReferralListStatus = []
              break
            case 'activeReferral':
              state.childFilterItems.activeListStatus = []
              break
            case 'assignedReferrals':
              state.childFilterItems.assignedReferralListStatus = []
              break
            default:
              break
          }
          break
        case 6:
          state.childFilterItems.routes = []
          break
        case 7:
          state.childFilterItems.holdReasons = []
          break
        case 8:
          state.childFilterItems.createdDate = ''
          break
        case 9:
          state.childFilterItems.lostReasons = []
          break
        default:
          break
      }
    },
    setRefreshForSourceMessageData: (state, action) => {
      state.refreshForSourceMessageData = action.payload
    },
    setSourceMessageTabToSet: (state, action) => {
      state.sourceMessageTabToSet = action.payload
    },
    setReferralListInputs: (state, action) => {
      const props = action.payload as { type: string; data: ReferralListInputsParams }
      switch (props.type) {
        case 'matched':
          state.referralListInputs.matched = props.data
          break
        case 'lost':
          state.referralListInputs.lost = props.data
          break
        case 'hold':
          state.referralListInputs.hold = props.data
          break
        case 'unmatched':
          state.referralListInputs.unMatched = props.data
          break
        case 'myReferral':
          state.referralListInputs.myReferral = props.data
          break
        case 'parentReferral':
          state.referralListInputs.parentReferral = props.data
          break
        case 'activeReferral':
          state.referralListInputs.active = props.data
          break
        case 'all':
          state.referralListInputs.all = props.data
          break
        case 'fax':
          state.referralListInputs.fax = props.data
          break
        case 'assignedReferrals':
          state.referralListInputs.assignedReferrals = props.data
          break
        case 'patientFax':
          state.referralListInputs.patientFax = props.data
          break
        default:
          break
      }
    },
    setIsTaskListPending: (state, action) => {
      state.isTaskListPending = action.payload
    },
    setWaystarValidationChanged: state => {
      state.waystarValidationChanged = !state.waystarValidationChanged
    },
    setHoldReasons: (state, action) => {
      state.holdReasons = action.payload
    },
    setLostReasons: (state, action) => {
      state.lostReasons = action.payload
    },
    setDenialReasonList: (state, action) => {
      state.denialReasonList = action.payload
    },
    setReferralGridViewList: (state, action) => {
      state.referralGridViewList = action.payload
    },
    setFacilityListForParentFilter: (state, action) => {
      state.facilityListForParentFilter = action.payload
    },
    setFacilityListForChildFilter: (state, action) => {
      state.facilityListForChildFilter = action.payload
    },
    setInsuranceProvider: (state, action) => {
      state.insuranceProvider = action.payload
    },
    setPVerifyLinks: (state, action) => {
      state.pVerifyLinks = action.payload
    },
    setPVerifyRequestId: (state, action) => {
      state.pVerifyRequestId = action.payload
    },
    setPVerifyValidationChanged: state => {
      state.pVerifyValidationChanged = !state.pVerifyValidationChanged
    },
    setSelectedParentTab: (state, action) => {
      state.selectedParentTab = action.payload
    },
    setReferralComparisonId: (state, action) => {
      state.referralDetail.referralComparisonId = action.payload
    },
    setFaxStatusRefresh: state => {
      state.faxStatusRefresh = !state.faxStatusRefresh
    },
    setOcrCompletionStatus: (state, action) => {
      state.referralDetail.ocrGenerated = action.payload
    },
    setAIProcessingStatus: (state, action) => {
      state.referralDetail.isAIAnswerGenerated = action.payload
    },
    setRefreshRefList: state => {
      state.refreshRefList = !state.refreshRefList
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const referralActions = {
  setReferralDetail: referralSlice.actions.setReferralDetail,
  setReferralList: referralSlice.actions.setReferralList,
  setSelectedKeyword: referralSlice.actions.setSelectedKeyword,
  setAllKeyword: referralSlice.actions.setAllKeyword,
  setIsQuestionAnswersLoaded: referralSlice.actions.setIsQuestionAnswersLoaded,
  setCategoryDetailList: referralSlice.actions.setCategoryDetailList,
  setWaystarMatch: referralSlice.actions.setWaystarMatch,
  setPVerifyMatch: referralSlice.actions.setPVerifyMatch,
  setSelectedReferral: referralSlice.actions.setSelectedReferral,
  setSelectedParentReferral: referralSlice.actions.setSelectedParentReferral,
  setSelectedListTab: referralSlice.actions.setSelectedListTab,
  setReferralListRoot: referralSlice.actions.setReferralListRoot,
  setRouteList: referralSlice.actions.setRouteList,
  setOrganizationList: referralSlice.actions.setOrganizationList,
  setParentFilterItems: referralSlice.actions.setParentFilterItems,
  setChildFilterItems: referralSlice.actions.setChildFilterItems,
  setGridStatusFilterItems: referralSlice.actions.setGridStatusFilterItems,
  setRefreshForSourceMessageData: referralSlice.actions.setRefreshForSourceMessageData,
  setSourceMessageTabToSet: referralSlice.actions.setSourceMessageTabToSet,
  setReferralListInputs: referralSlice.actions.setReferralListInputs,
  setIsTaskListPending: referralSlice.actions.setIsTaskListPending,
  setWaystarValidationChanged: referralSlice.actions.setWaystarValidationChanged,
  setHoldReasons: referralSlice.actions.setHoldReasons,
  setLostReasons: referralSlice.actions.setLostReasons,
  setDenialReasonList: referralSlice.actions.setDenialReasonList,
  setReferralGridViewList: referralSlice.actions.setReferralGridViewList,
  setFacilityListForParentFilter: referralSlice.actions.setFacilityListForParentFilter,
  setFacilityListForChildFilter: referralSlice.actions.setFacilityListForChildFilter,
  setInsuranceProvider: referralSlice.actions.setInsuranceProvider,
  setPVerifyLinks: referralSlice.actions.setPVerifyLinks,
  setPVerifyRequestId: referralSlice.actions.setPVerifyRequestId,
  setPVerifyValidationChanged: referralSlice.actions.setPVerifyValidationChanged,
  setSelectedParentTab: referralSlice.actions.setSelectedParentTab,
  setReferralComparisonId: referralSlice.actions.setReferralComparisonId,
  setFaxStatusRefresh: referralSlice.actions.setFaxStatusRefresh,
  setOcrCompletionStatus: referralSlice.actions.setOcrCompletionStatus,
  setAIProcessingStatus: referralSlice.actions.setAIProcessingStatus,
  setSourceHospitalList: referralSlice.actions.setSourceHospitalList,
  clearIndividualParentFilter: referralSlice.actions.clearIndividualParentFilter,
  clearIndividualChildFilter: referralSlice.actions.clearIndividualChildFilter,
  setRefreshRefList: referralSlice.actions.setRefreshRefList,
}

// Selectors
export const selectReferralDetail = (state: RootState) => state.referral.referralDetail
export const selectReferralList = (state: RootState) => state.referral.referralList
export const getSelectedKeyword = (state: RootState) => state.referral.selectedKeyword
export const getAllKeyword = (state: RootState) => state.referral.allKeyword
export const getIsQuestionAnswersLoaded = (state: RootState) =>
  state.referral.isQuestionAnswersLoaded
export const getCategoryDetailList = (state: RootState) => state.referral.categoryDetailList

export const getSelectedReferral = (state: RootState) => state.referral.selectedReferral
export const selectedParentReferral = (state: RootState) => state.referral.selectedParentReferral
export const getSelectedListTab = (state: RootState) => state.referral.selectedListTab
export const getReferralListRoot = (state: RootState) => state.referral.referralListRoot
export const getRouteList = (state: RootState) => state.referral.routeList
export const getSourceHospitalList = (state: RootState) => state.referral.sourceHospitalList
export const getOrganizationList = (state: RootState) => state.referral.organizationList
export const getParentFilterItems = (state: RootState) => state.referral.parentFilterItems
export const getChildFilterItems = (state: RootState) => state.referral.childFilterItems
export const getRefreshForSourceMessageData = (state: RootState) =>
  state.referral.refreshForSourceMessageData
export const getSourceMessageTabToSet = (state: RootState) => state.referral.sourceMessageTabToSet

export const getReferralListInputs = (state: RootState) => state.referral.referralListInputs
export const getIsTaskListPending = (state: RootState) => state.referral.isTaskListPending
export const waystarValidationChanged = (state: RootState) =>
  state.referral.waystarValidationChanged
export const getHoldReasons = (state: RootState) => state.referral.holdReasons
export const getLostReasons = (state: RootState) => state.referral.lostReasons
export const getDenialReasonList = (state: RootState) => state.referral.denialReasonList
export const getReferralGridViewList = (state: RootState) => state.referral.referralGridViewList
export const getFacilityListForParentFilter = (state: RootState) =>
  state.referral.facilityListForParentFilter
export const getFacilityListForChildFilter = (state: RootState) =>
  state.referral.facilityListForChildFilter
export const getInsuranceProvider = (state: RootState) => state.referral.insuranceProvider
export const getPVerifyLinks = (state: RootState) => state.referral.pVerifyLinks
export const getPVerifyRequestId = (state: RootState) => state.referral.pVerifyRequestId
export const pVerifyValidationChanged = (state: RootState) =>
  state.referral.pVerifyValidationChanged
export const getSelectedParentTab = (state: RootState) => state.referral.selectedParentTab
export const getFaxStatusRefresh = (state: RootState) => state.referral.faxStatusRefresh
export const getRefreshForRefList = (state: RootState) => state.referral.refreshRefList
// Reducer
export default referralSlice.reducer
