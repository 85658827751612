import { Env } from 'config/Env'
import {
  OrganizationDetailsRequest,
  PromiseFeatureForm,
  OrganizationCreateResponse,
  OrganizationGetResponse,
  FeatureListResponse,
  QuestionBank,
  CreateCategory,
  UpdateCategory,
  AddKeywordDataResponse,
  AddKeywordRequest,
  WordBankSelectedDeatil,
  ListData,
  OrganizationStatusRequest,
  UpdateQuestionBankPriorityReq,
  AIForQAStatus,
} from 'features/organization/types'
import { DropDownEnvelope } from 'utils/types/DropDownEnvelope'
import { Envelope } from 'utils/types/Envelope'

import { HttpClient, ApiRequestTypes } from '../../../utils/apiService/httpService'
import { AddHraRequest } from '../types/hraTypes'

const ORGANIZATION_CREATE_BASE_URL = `${Env.REACT_APP_API_URL}Organization/api/Organization/create`
const GET_ALL_FEATURES_URL = `${Env.REACT_APP_API_URL}Organization/api/feature/get`
const ORGANIZATION_DELETE_BASE_URL = `${Env.REACT_APP_API_URL}Organization/api/Organization/delete`
const GET_ORGANIZATION_DATA = `${Env.REACT_APP_API_URL}Organization/api/Organization/getbyid`
const PUT_ORGANIZATION_DATA = `${Env.REACT_APP_API_URL}Organization/api/Organization/update`
const GET_ORGANIZATION_DETAILS = `${Env.REACT_APP_API_URL}Organization/api/Organization/getdetailbyid`
const GET_QUESTION_BANK_DETAIL_LIST = `${Env.REACT_APP_API_URL}Organization/api/QuestionBank/GetAllQuestionBank`
const ADD_OR_UPDATE_QUESTIONBANK_URL = `${Env.REACT_APP_API_URL}Organization/api/QuestionBank/SaveQuestionBank`
const QUESTION_DELETE_URL = `${Env.REACT_APP_API_URL}Organization/api/QuestionBank/DeleteQuestion`

const ADD_CATEGORY_URL = `${Env.REACT_APP_API_URL}Organization/api/Category/create`
const UPDATE_CATEGORY_URL = `${Env.REACT_APP_API_URL}Organization/api/Category/update`
const GET_CATEGORIES_URL = `${Env.REACT_APP_API_URL}Organization/api/FacilityCategory/GetAllCategoryById`

const GET_ALL_FACILITY_URL = `${Env.REACT_APP_API_URL}Organization/api/Facility/GetListByOrganizationId`
const ADD_KEYWORD_URL = `${Env.REACT_APP_API_URL}Organization/api/Wordbank/saveWordBank`
const GET_SAVED_KEYWORDS_URL = `${Env.REACT_APP_API_URL}Organization/api/Wordbank/GetDetailbyCategoryId`
const GET_WORD_BANK_DETAIL_LIST_URL = `${Env.REACT_APP_API_URL}Organization/api/Wordbank/GetWordBankDetailbyOrganizationId`
const GET_CATEGORIES_FOR_ADDMORE_URL = `${Env.REACT_APP_API_URL}Organization/api/Category/GetCategoryListForAddMore`
const DELETE_WORD_BANK_URL = `${Env.REACT_APP_API_URL}Organization/api/Wordbank/delete`
const PUT_ORGANIZATION_STATUS = `${Env.REACT_APP_API_URL}Organization/api/Organization/OrganizationStatusUpdate`
const DELETE_CATEGORY_URL = `${Env.REACT_APP_API_URL}Organization/api/Category/DeleteCategory`
const UPDATE_PRIORITY_URL = `${Env.REACT_APP_API_URL}Organization/api/QuestionBank/UpdateQuestionBankPriority`
const CREATE_HRA = `${Env.REACT_APP_API_URL}Referral/api/HighRiskAdmission/CreateHighRiskAdmission`
const HAS_AI_CONFIGURED = `${Env.REACT_APP_API_URL}Organization/api/Organization/IsAIEnabledForQA`

export const organizationCreate = (
  organizationData: OrganizationDetailsRequest,
): Promise<OrganizationCreateResponse> =>
  HttpClient[ApiRequestTypes.post](ORGANIZATION_CREATE_BASE_URL, organizationData)
export const getAllFeaturesList = (): Promise<PromiseFeatureForm> =>
  HttpClient[ApiRequestTypes.get](GET_ALL_FEATURES_URL)

export const deleteOrganization = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](`${ORGANIZATION_DELETE_BASE_URL}?id=${id}`)
export const getOrganizationById = (id: number): Promise<OrganizationGetResponse> =>
  HttpClient[ApiRequestTypes.get](`${GET_ORGANIZATION_DATA}?id=${id}`)
export const updateOrganization = (
  organizationData: OrganizationDetailsRequest,
): Promise<Envelope> => HttpClient[ApiRequestTypes.put](PUT_ORGANIZATION_DATA, organizationData)
export const getFeatureList = (): Promise<FeatureListResponse> =>
  HttpClient[ApiRequestTypes.get](GET_ALL_FEATURES_URL)
export const getOrganizationDetails = (id: number): Promise<OrganizationGetResponse> =>
  HttpClient[ApiRequestTypes.get](`${GET_ORGANIZATION_DETAILS}?id=${id}`)
export const questionBankDetailList = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_QUESTION_BANK_DETAIL_LIST}?id=${id}`)
export const createOrUpdateQuestionBank = (data: QuestionBank): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](ADD_OR_UPDATE_QUESTIONBANK_URL, data)
export const deleteQuestion = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.del](`${QUESTION_DELETE_URL}?id=${id}`)
export const addCategory = (category: CreateCategory): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](ADD_CATEGORY_URL, category)
export const updateCategory = (category: UpdateCategory): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](UPDATE_CATEGORY_URL, category)

export const getCategories = (organizationId: number): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_CATEGORIES_URL}?organizationId=${organizationId}`)

export const addKeywords = (data: AddKeywordRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](ADD_KEYWORD_URL, data)
export const getSavedKeywords = (data: AddKeywordDataResponse): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_SAVED_KEYWORDS_URL}?categoryId=${data.categoryId}`)
export const getCategoriesForAddMore = (data: WordBankSelectedDeatil): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.get](
    `${GET_CATEGORIES_FOR_ADDMORE_URL}?organizationId=${data.organizationId}&facilityId=${data.facilityId}`,
  )

export const getWordBankDetailList = (organizationId: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](
    `${GET_WORD_BANK_DETAIL_LIST_URL}?organizationId=${organizationId}`,
  )

export const deleteWordbank = (categoryId: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](`${DELETE_WORD_BANK_URL}?id=${categoryId}`)

export const getAllFacilities = (organizationId: number): Promise<{ data: ListData[] }> =>
  HttpClient[ApiRequestTypes.get](`${GET_ALL_FACILITY_URL}?organizationId=${organizationId}`)

export const organizationStatusUpdate = (orgStatus: OrganizationStatusRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](PUT_ORGANIZATION_STATUS, orgStatus)

export const deleteCategory = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](`${DELETE_CATEGORY_URL}?id=${id}`)
export const updateQuestionBankPriorities = (
  data: UpdateQuestionBankPriorityReq,
): Promise<Envelope> => HttpClient[ApiRequestTypes.post](UPDATE_PRIORITY_URL, data)
export const createHra = (data: AddHraRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](CREATE_HRA, data)
export const hasAIForQAConfigured = (id: number): Promise<AIForQAStatus> =>
  HttpClient[ApiRequestTypes.get](`${HAS_AI_CONFIGURED}?organizationId=${id}`)
