/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'qs'

import { Env } from 'config/Env'
import {
  LoginFormInput,
  LoginResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ChangePasswordRequest,
  ResetPasswordRequest,
  Profile,
  UserSettingInfo,
  UpdateNotifReq,
} from 'features/account/types'
import { AssignLeads, AssignListResponse } from 'features/referrals/types'
import { Envelope } from 'utils/types/Envelope'

import { HttpClient, ApiRequestTypes } from '../../../utils/apiService/httpService'

const LOGIN_BASE_URL = `${Env.REACT_APP_API_URL}Identity/api/TOKEN/TOKEN`
const FORGOT_PASSWORD_URL = `${Env.REACT_APP_API_URL}Identity/api/Account/ForgotPassword`
const CHANGE_PASSWORD_URL = `${Env.REACT_APP_API_URL}Identity/api/Account/ChangePassword`
const RESET_PASSWORD_URL = `${Env.REACT_APP_API_URL}Identity/api/Account/ResetPassword`

const GET_PROFILE_DETAILS_URL = `${Env.REACT_APP_API_URL}Identity/api/Profile/GetProfileDetails`
const UPDATE_PROFILE_URL = `${Env.REACT_APP_API_URL}Identity/api/Profile/UpdateProfileDetails`
const UPDATE_PROFILE_IMAGE_URL = `${Env.REACT_APP_API_URL}Identity/api/Profile/UploadProfileImage`
const GET_USER_SETTINGS = `${Env.REACT_APP_API_URL}Identity/api/Profile/GetUserSetting`
const UPDATE_USER_SETTINGS = `${Env.REACT_APP_API_URL}Identity/api/Profile/UpdateUserSettings`
const GET_NOTIFICATION_LIST = `${Env.REACT_APP_API_URL}Identity/api/ConfigurationSettings/GetAllNotificationConfiguration`
const UPDATE_NOTIFICATION_LIST = `${Env.REACT_APP_API_URL}Identity/api/ConfigurationSettings/SaveNotificationConfiguration`
const REFERRAL_ASSIGN_USERS_LIST = `${Env.REACT_APP_API_URL}Identity/api/User/GetAssignList`

export const loginCall = (loginData: LoginFormInput): Promise<LoginResponse> =>
  HttpClient[ApiRequestTypes.post](LOGIN_BASE_URL, qs.stringify(loginData))

export const forgotPasswordCall = (
  ForgotPassword: ForgotPasswordRequest,
): Promise<ForgotPasswordResponse> =>
  HttpClient[ApiRequestTypes.post](FORGOT_PASSWORD_URL, ForgotPassword)

export const changePasswordCall = (
  ResetPassword: ChangePasswordRequest,
): Promise<ForgotPasswordResponse> =>
  HttpClient[ApiRequestTypes.post](CHANGE_PASSWORD_URL, ResetPassword)

export const resetPasswordCall = (
  ResetPassword: ResetPasswordRequest,
): Promise<ForgotPasswordResponse> =>
  HttpClient[ApiRequestTypes.post](RESET_PASSWORD_URL, ResetPassword)

export const fetchProfileDetails = (id: string): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_PROFILE_DETAILS_URL}?userId=${id}`)

export const updateProfile = (data: Profile): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](UPDATE_PROFILE_URL, data)

export const updateProfileImage = (imageData: FormData): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](UPDATE_PROFILE_IMAGE_URL, imageData)

export const fetchUserSettings = (id: string): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_USER_SETTINGS}?userId=${id}`)

export const updateUserSettings = (settingsData: UserSettingInfo): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](UPDATE_USER_SETTINGS, settingsData)

export const fetchNotificationSettings = (): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](GET_NOTIFICATION_LIST)
export const saveNotificationSettings = (data: UpdateNotifReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](UPDATE_NOTIFICATION_LIST, data)
export const fetchAssignUsersList = (requestBody: AssignLeads): Promise<AssignListResponse> =>
  HttpClient[ApiRequestTypes.post](REFERRAL_ASSIGN_USERS_LIST, requestBody)
