export const LightColors = {
  colorMode: 'light' as 'light' | 'dark',
  primary: {
    main: '#3D5567', // Default - Tab Selected
    light: 'rgba(61, 85, 103, 0.08)', // Default - Button
  },
  secondary: {
    main: '#E0E0E0', // used - borderColor
    light: '#3D5567', // used - Button
  },
  background: {
    default: '#FFFFFF', // used
    paper: '#F7F9FC', // used
  },
  text: {
    primary: '#000000', // used - Main, Header Title,
    secondary: '#3D5567', // used - Menu
  },
  gccCustomColors: {
    boxTitle: '#1E2A33',
    iconButtonMain: '#5B7F9A',
    textDescription: 'rgba(0, 0, 0, 0.60)',
    subMessage: 'rgba(0, 0, 0, 0.87)',
    lightGreen: '#00B4804D',
    iconColorSub: 'rgba(255, 255, 255, 1)',
    tableRowMain: 'white',
    tableRowSub: '#3D556710',
    tableRowHover: '#00B4801A',
  },
}
