import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from 'store/store'

import { EmbedTokenResponse } from '../types'

export interface DashboardState {
  embedToken: EmbedTokenResponse
}
const initialState: DashboardState = {
  embedToken: {
    accessToken: undefined,
  },
}

// slice
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDomoEmbedToken(state, action) {
      state.embedToken = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const dashboardActions = {
  setDomoEmbedToken: dashboardSlice.actions.setDomoEmbedToken,
}

// Selectors
export const getEmbedToken = (state: RootState) => state.dashboard?.embedToken

// Reducer
export default dashboardSlice.reducer
