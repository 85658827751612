import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { loginCall } from 'features/account/api'
import { loginActions } from 'features/account/store/login.slice'
import {LoginFormInput,LoginUserInfo} from 'features/account/types'

export function* onGetLogin(obj:LoginUserInfo): SagaIterator {
  yield put(loginActions.loginSucceeded(obj))
}

function* onCreateLogin({
  payload,
}: {
  type: typeof  loginActions.login
  payload: LoginFormInput
}): SagaIterator {
  try {
    const details = yield call(loginCall, payload);
    if(details.data.isSuccess === true){
      localStorage.setItem('token', details?.data?.data?.accessToken);
     
    }
    else{
      const user:LoginUserInfo = {};
      user.success =  false;
      user.message = "Login_Failed"
      yield put(loginActions.loginSucceeded(user))

    }
  
  }
  catch (error) {
    const user:LoginUserInfo = {};
    user.success =  false;
    user.message = "Login_Failed"
    yield put(loginActions.loginSucceeded(user))

  }


}



// Watcher Saga
export function* loginWatcherSaga(): SagaIterator {
  yield takeEvery(loginActions.login.type, onCreateLogin)
}

export default loginWatcherSaga
