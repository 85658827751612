import * as Sentry from '@sentry/react'

import Env from 'config/Env'

const logApiBody = (title: string, data: unknown, logType: string, userId?: string) => {
  if (!Env.isLocal()) {
    Sentry.captureMessage(`${title} - ${logType}`, {
      level: 'info',
      extra: { data, logType },
      tags: { userId },
    })
  }
}

export const logApiRequest = (title: string, requestData: unknown, userId?: string) => {
  logApiBody(title, requestData, 'API Request', userId)
}

export const logApiResponse = (title: string, responseData: unknown, userId?: string) => {
  logApiBody(title, responseData, 'API Response', userId)
}

export const logApiRequestFailed = (message: string) => {
  if (!Env.isLocal())
    Sentry.captureMessage(`API Request Failed`, { level: 'error', extra: { message } })
}

export const logApiException = (error: unknown) => {
  if (!Env.isLocal()) Sentry.captureMessage(`API Exception`, { level: 'error', extra: { error } })
}

export const logTransaction = (title: string, data?: unknown) => {
  if (!Env.isLocal()) Sentry.captureMessage(title, { level: 'info', extra: { data } })
}
