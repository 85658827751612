import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { ThreadDetails } from 'features/referrals/types'
import type { RootState } from 'store/store'

import {
  ChatDetails,
  ChatMemberDetails,
  ChatNotificationDetails,
  DeleteChatDetails,
} from '../types'

export interface DiscussionState {
  discussionDetail: string
  discussionList: string
  newChannelId: string
  selectedThread: ThreadDetails
  newChatDetails: ChatDetails | null
  deletedChatDetails: DeleteChatDetails | null
  chatNotificationList: ChatNotificationDetails[]
  unreadChatThreadCount: number
  newMemberDetails: ChatMemberDetails | null
}
const initialState: DiscussionState = {
  discussionDetail: '',
  discussionList: '',
  newChannelId: '',
  selectedThread: {
    referralId: '',
    facilityName: '',
    patientName: '',
    threadId: '',
    patientId: 0,
    myMemberState: '',
  },
  newChatDetails: null,
  deletedChatDetails: null,
  newMemberDetails: null,
  chatNotificationList: [],
  unreadChatThreadCount: 0,
}
// slice
export const discussionSlice = createSlice({
  name: 'discussion',
  initialState,
  reducers: {
    setSelectedThreadId: (state, action) => {
      state.selectedThread = action.payload
    },
    setNewChannelId: (state, action) => {
      state.newChannelId = action.payload
    },

    setNewChatDetails: (state, action) => {
      state.newChatDetails = action.payload
    },
    setDeletedChatDetails: (state, action) => {
      state.deletedChatDetails = action.payload
    },
    setChatNotificationList: (state, action) => {
      state.chatNotificationList = action.payload
    },
    setUnreadChatThreadCount: (state, action) => {
      state.unreadChatThreadCount = action.payload
    },
    setNewMemberDetails: (state, action) => {
      state.newMemberDetails = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})
// Actions
export const discussionActions = {
  setSelectedThreadId: discussionSlice.actions.setSelectedThreadId,
  setNewChannelId: discussionSlice.actions.setNewChannelId,
  setNewChatDetails: discussionSlice.actions.setNewChatDetails,
  setDeletedChatDetails: discussionSlice.actions.setDeletedChatDetails,
  setChatNotificationList: discussionSlice.actions.setChatNotificationList,
  setUnreadChatThreadCount: discussionSlice.actions.setUnreadChatThreadCount,
  setNewMemberDetails: discussionSlice.actions.setNewMemberDetails,
}
// Selectors
export const getNewChannelId = (state: RootState) => state.discusssion.newChannelId
export const getSelectedThread = (state: RootState) => state.discusssion.selectedThread
export const getNewChatDetails = (state: RootState) => state?.discusssion?.newChatDetails
export const getDeletedChatDetails = (state: RootState) => state?.discusssion?.deletedChatDetails
export const getChatNotificationList = (state: RootState) =>
  state?.discusssion?.chatNotificationList
export const getUnreadChatThreadCount = (state: RootState) =>
  state?.discusssion?.unreadChatThreadCount
export const getNewMemberDetails = (state: RootState) => state?.discusssion?.newMemberDetails

// Reducer
export default discussionSlice.reducer
