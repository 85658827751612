/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs'

import { Env } from 'config/Env'
import { setCookie } from 'utils/cookieUtils'

const LOGIN_BASE_URL = `${Env.REACT_APP_API_URL}Identity/api/TOKEN/TOKEN`

let gccInterceptor: number
export const setInterceptors = (contentType: string, token?: string) => {
  gccInterceptor = axios.interceptors.request.use(
    config => {
      config.headers = {
        ...{ Authorization: `Bearer ${token}` },
        'Content-Type': contentType,
      }
      return config
    },
    error => Promise.reject(error),
  )
}

const removeInterceptor = (interceptor: number) => {
  axios.interceptors.request.eject(interceptor)
}

export const removeAuthorization = () => {
  axios.interceptors.request.eject(gccInterceptor)
}

const goToReload = () => {
  window.location.reload()
}

export const clearCache = () => {
  removeAuthorization()
  removeInterceptor(gccInterceptor)
  Cookies.remove('gcc_access_token')
  Cookies.remove('gcc_refresh_token')
  return 'Session removed'
}

export const ApiRequestTypes = {
  get: 'get',
  post: 'post',
  del: 'del',
  put: 'put',
  interceptors: 'interceptors',
  removeInterceptor: 'removeInterceptor',
  removeAuthorization: 'removeAuthorization',
}

// Create Refresh token Form Data
export const createRefreshTokenFormData = () => ({
  grant_type: 'refresh_token',
  scope: 'openid offline_access referralscope',
  client_id: 'gccweb',
  client_secret: '?M[Qc;8&T^&UHyu',
  refresh_token: Cookies.get('gcc_refresh_token'),
})

setCookie('isAlreadyFetchingAccessToken', 'false')
let subscribers: Array<any> = []

function onAccessTokenFetched(access_token: string) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback: any) {
  subscribers.push(callback)
}
axios.interceptors.response.use(
  resp => resp,
  async error => {
    const {
      config,
      response: { status },
    } = error
    const originalRequest = config

    if (status === 401) {
      if (Cookies.get('isAlreadyFetchingAccessToken') === 'false') {
        setCookie('isAlreadyFetchingAccessToken', 'true')
        removeInterceptor(gccInterceptor)
        removeAuthorization()
        setInterceptors('application/x-www-form-urlencoded', '')
        const resp = await axios.post(LOGIN_BASE_URL, qs.stringify(createRefreshTokenFormData()))
        if (resp.status === 200 && resp?.data?.isSuccess) {
          setCookie('isAlreadyFetchingAccessToken', 'false')
          removeInterceptor(gccInterceptor)
          removeAuthorization()
          setInterceptors('application/json', resp?.data?.data?.accessToken)

          setCookie('gcc_access_token', resp?.data?.data?.accessToken)
          setCookie('gcc_refresh_token', resp?.data?.data?.refreshToken)
          onAccessTokenFetched(resp?.data?.data?.accessToken)
          return axios(originalRequest)
        }
        setCookie('isAlreadyFetchingAccessToken', 'false')
        clearCache()
        goToReload()
      } else if (originalRequest?.url === LOGIN_BASE_URL) {
        clearCache()
        goToReload()
      }

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber((access_token: string) => {
          originalRequest.headers.Authorization = `Bearer ${access_token}`
          resolve(axios(originalRequest))
        })
      })
      return retryOriginalRequest
    }
    return Promise.reject(error)
  },
)

const post = (url = '', data = {}, config = {}) =>
  axios.post(url, data, Object.assign(config, { timeout: 15 * 60 * 1000 }))

const get = (url = '', config = {}) => axios.get(url, config)

const put = (url = '', data = {}, config = {}) => axios.put(url, data, config)

const del = (url = '', config = {}) => axios.delete(url, config)

const HttpClient: any = {
  post,
  get,
  put,
  setInterceptors,
  del,
  removeAuthorization,
  removeInterceptor,
}

export { HttpClient }
