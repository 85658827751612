import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    theme: 'light',
  },
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload
    },
  },
})

// Actions
export const themeActions = {
  setTheme: themeSlice.actions.setTheme,
}

// Selectors
export const selectTheme = (state: RootState) => state.appTheme?.theme

// Reducer
export default themeSlice.reducer
