import createSagaMiddleware from '@redux-saga/core'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import layoutReducer from 'components/Layout/store/layoutSlice'
import themeReducer from 'components/Theme/store/themeSlice'
import { Env } from 'config/Env'
import loginReducer from 'features/account/store/login.slice'
import dashboardReducer from 'features/dashboard/store/dashboard.slice'
import chatReducer from 'features/discussions/store/discussion.slice'
import facilityReducer from 'features/facilities/store/facility.slice'
import notificationsReducer from 'features/notifications/store/notifications.slice'
import organizationReducer from 'features/organization/store/organization.slice'
import referralReducer from 'features/referrals/store/referral.slice'
import rolesReducer from 'features/rolesAndPermissions/store/rolesPermissions.slice'
import userReducer from 'features/userManagement/store/userManagement.slice'
import { rootSaga } from 'store/rootSaga'

const { routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  reduxTravelling: Env.isDev() || Env.isLocal(),
})

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  loginDetails: loginReducer,
  organization: organizationReducer,
  facility: facilityReducer,
  router: routerReducer,
  appTheme: themeReducer,
  referral: referralReducer,
  roles: rolesReducer,
  users: userReducer,
  appLayout: layoutReducer,
  discusssion: chatReducer,
  notifications: notificationsReducer,
  dashboard: dashboardReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: persistedReducer,
    devTools: Env.isDev() || Env.isLocal(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware).concat(routerMiddleware),
  })

  sagaMiddleware.run(rootSaga)

  return store
}

export const store = makeStore()
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const history = {}
